import store, { RootState } from '@/store'
import {
  CanChangeCartTypeD,
  CartType,
  datiStornoD,
  Getters,
  IntoGiacenzaCapiT,
  SalesState,
  ShippingAddress,
  ShippingDetails,
  Statistics,
  UsedCash,
} from './sales-types'
import {
  ApiV1SalesDataAttributes,
  ApiV1SalesDataAttributesCapi,
  ApiV1SalesDataAttributesPagamenti,
  ApiV1SalesSaleIdDataAttributes,
  ApiV1SalesSaleIdDataAttributesCapi,
  ApiV1SalesSaleIdDataAttributesPagamenti,
  GetConsumerExtraInfoDataAttributes,
  GetCountriesAttributes,
  GetSkuDataAttributes,
} from '@/api'
import {
  AnonymousConsumers,
  SaleStoreStatus,
  TipiMovimenti,
  TipiVendita,
} from '@/addons/enums'
import { SalesGetters } from './sales-store'
import { ConfigGetters } from '../configs-store'
import { CostiExtraType, PaymentsType } from '../payments-store'
import { GetterTree } from 'vuex'
import { compareAsc, parse, subDays } from 'date-fns'
import {
  CashGenericPaymentMethodForm,
  PaymentMethodForm,
} from '@/interfaces/payment-method-form'
import { SuspendedCreditForm } from '@/components/sales/payments/payment-suspended-credit/suspendedCredit'

import { ConsumersGetters } from '../consumer'
import { GenericGetters } from '../generic-store'
import { isConfigTruthy } from '@/addons/functions/configs'

export const salesGetters = {
  [Getters.GET_RDV]: (state: SalesState) => state.selected_rdv,
  [Getters.GET_CASHIER]: (state: SalesState) => state.selected_cashier,
  [Getters.GET_CONSUMER]: (state: SalesState) => state.consumer,
  [Getters.GET_RDV_ID]: (state: SalesState): string =>
    state.selected_rdv?.venditrice ?? '',
  [Getters.GET_CASHIER_ID]: (state: SalesState): string =>
    state.selected_cashier?.venditrice ?? '',
  [Getters.GET_CONSUMER_ID]: (state: SalesState): string =>
    state.consumer?.pk_consumer ?? '',
  [Getters.GET_ANONYMOUS_ID]: (state: SalesState): string =>
    state.currentSale?.pk_consumer ?? '',
  [Getters.GET_RDV_LABEL]: (state: SalesState): string => {
    if (state.selected_rdv) {
      return `${state.selected_rdv?.venditrice} - ${state.selected_rdv?.descrizione}`
    }
    return ''
  },
  [Getters.GET_CASHIER_LABEL]: (state: SalesState): string => {
    if (state.selected_cashier) {
      return `${state.selected_cashier?.venditrice} - ${state.selected_cashier?.descrizione}`
    }
    return ''
  },
  [Getters.GET_CUSTOMER_LABEL]: (state: SalesState) => {
    const consumerAttributes = state.consumer
    if (!consumerAttributes || !consumerAttributes?.cognome) {
      return
    }
    return `${consumerAttributes?.cognome} ${consumerAttributes?.nome || ''}`
  },
  [Getters.GET_PAYMENTS]: (
    state: SalesState,
  ): Array<ApiV1SalesDataAttributesPagamenti> =>
    state.currentSale.pagamenti ?? [],
  [Getters.GET_ONLY_PAYMENTS]: (
    state: SalesState,
  ): Array<ApiV1SalesDataAttributesPagamenti> =>
    state.currentSale.pagamenti?.filter(
      (p: ApiV1SalesDataAttributesPagamenti) =>
        p.codice_movimento === TipiMovimenti.CONTABILITA_PAGAMENTO,
    ) ?? [],
  [Getters.GET_DISCOUNT]: (
    state: SalesState,
  ):
    | ApiV1SalesDataAttributesPagamenti[]
    | ApiV1SalesSaleIdDataAttributesPagamenti[] => {
    if (state.currentSale.pagamenti) {
      return state.currentSale.pagamenti.filter(
        (p) => p.codice_movimento === TipiMovimenti.CONTABILITA_SCONTO,
      )
    }
    return []
  },
  [Getters.GET_LOYALTY_PROGRAM_DISCOUNT]: (
    state: SalesState,
  ):
    | ApiV1SalesDataAttributesPagamenti[]
    | ApiV1SalesSaleIdDataAttributesPagamenti[] => {
    if (state.currentSale.pagamenti) {
      return state.currentSale.pagamenti.filter(
        (p) => p.codice_movimento === TipiMovimenti.CONTABILITA_PROMOZIONE,
      )
    }
    return []
  },
  [Getters.GET_REBATE]: (state: SalesState): number => {
    if (state.currentSale.pagamenti) {
      return state.currentSale.pagamenti
        .filter((p) => p.codice_movimento === TipiMovimenti.CONTABILITA_ABBUONO)
        .map((p) => p.importo_finale ?? 0)
        .reduce((t: number, p: number) => t + p, 0)
    }
    return 0
  },
  [Getters.GET_TAILORING]: (state: SalesState): number => {
    if (state.currentSale.pagamenti) {
      return state.currentSale.pagamenti
        .filter(
          (p) => p.codice_movimento === TipiMovimenti.CONTABILITA_SARTORIA,
        )
        .map((p) => p.importo_finale ?? 0)
        .reduce((t: number, p: number) => t + p, 0)
    }
    return 0
  },
  // TODO: Remove this getters and use GET_TAILORING
  [Getters.GET_TAILORING_NO_EXTRA]: (state: SalesState): number => {
    if (state.currentSale.pagamenti) {
      return state.currentSale.pagamenti
        .filter(
          (p) =>
            p.codice_movimento === TipiMovimenti.CONTABILITA_SARTORIA &&
            p.progressivo_capo !== 0,
        )
        .map((p) => p.importo_finale ?? 0)
        .reduce((t: number, p: number) => t + p, 0)
    }
    return 0
  },
  [Getters.IS_CASH_AMOUNT_ABOVE_MAXPAYABLE]: (): boolean => {
    const cashAmount = store.getters[SalesGetters.GET_ONLY_PAYMENTS].find(
      (p: ApiV1SalesDataAttributesPagamenti) => p.cod_operazione === 'CONTANTI',
    )?.importo_iniziale

    return (
      cashAmount >= store.getters[ConfigGetters.GET_MAX_PAYABLE_CASH_AMOUNT]
    )
  },
  [Getters.GET_CHECK_ANTIRICICLAGGIO_RESULT]: (state: SalesState): boolean => {
    return state.checkAntireciclaggioResult
  },
  [Getters.GET_PRODUCT_TAILORING]: (state: SalesState): number => {
    if (state.currentSale.pagamenti) {
      return state.currentSale.pagamenti
        .filter(
          (p) =>
            p.codice_movimento === TipiMovimenti.CONTABILITA_SARTORIA &&
            p.progressivo_capo !== 0,
        )
        .map((p) => p.importo_finale ?? 0)
        .reduce((t: number, p: number) => t + p, 0)
    }
    return 0
  },
  [Getters.GET_EXTRA_TAILORING]: (state: SalesState): object => {
    if (state.currentSale.pagamenti) {
      return state.currentSale.pagamenti.filter(
        (p) =>
          p.codice_movimento === TipiMovimenti.CONTABILITA_SARTORIA &&
          p.progressivo_capo === 0,
      )
    }
    return []
  },
  [Getters.GET_FREE_EXTRA_TAILORING]: (state: SalesState): object => {
    if (state.currentSale.pagamenti) {
      return state.currentSale.pagamenti.filter(
        (p) =>
          p.codice_movimento === TipiMovimenti.CONTABILITA_SARTORIA_OMAGGIO &&
          p.progressivo_capo === 0,
      )
    }
    return []
  },
  [Getters.GET_EXTRA_TAILORING_PRICE]: (state: SalesState): number => {
    if (state.currentSale.pagamenti) {
      return state.currentSale.pagamenti
        .filter(
          (p) =>
            p.codice_movimento === TipiMovimenti.CONTABILITA_SARTORIA &&
            p.progressivo_capo === 0,
        )
        .map((p) => p.importo_finale ?? 0)
        .reduce((t: number, p: number) => t + p, 0)
    }
    return 0
  },
  [Getters.GET_PAYMENTS_TOTAL]: (state: SalesState): number => {
    if (state.currentSale.pagamenti) {
      return state.currentSale.pagamenti
        .filter(
          (p) => p.codice_movimento === TipiMovimenti.CONTABILITA_PAGAMENTO,
        )
        .map((p) => p.importo_iniziale ?? 0)
        .reduce((t: number, p: number) => t + p, 0)
    }
    return 0
  },
  [Getters.GET_TOTAL_AMOUNT_CASHED_IN]: (state: SalesState): number => {
    if (state.currentSale.pagamenti) {
      return state.currentSale.pagamenti
        .filter(
          (p) => p.codice_movimento === TipiMovimenti.CONTABILITA_PAGAMENTO,
        )
        .map((p) =>
          p.importo_iniziale
            ? p.cod_operazione !== PaymentsType.CREDITO_SOSPESO
              ? p.importo_iniziale
              : 0
            : 0,
        )
        .reduce((t: number, p: number) => t + p, 0)
    }
    return 0
  },
  [Getters.GET_CAPI]: (state: SalesState): ApiV1SalesDataAttributesCapi[] =>
    state.currentSale.capi ?? [],
  [Getters.GET_CAPI_PRICE]: (state: SalesState): number => {
    if (state.currentSale.capi?.length) {
      const capiPrice = state.currentSale.capi
        .map((c) => c.importo_finale ?? 0)
        .reduce((t: number, c: number) => t + c, 0)

      const alterationsPrice = state.currentSale.pagamenti
        ? state.currentSale.pagamenti
            .filter(
              (p) =>
                p.codice_movimento === TipiMovimenti.CONTABILITA_SARTORIA &&
                p.progressivo_capo !== 0,
            )
            .map((p) => p.importo_finale ?? 0)
            .reduce((t: number, p: number) => t + p, 0)
        : 0
      return capiPrice + alterationsPrice
    }
    return 0
  },
  [Getters.GET_TOTAL_PRICE]: (state: SalesState): number => {
    let totalPagamenti = 0
    if (state.currentSale.pagamenti) {
      totalPagamenti = state.currentSale.pagamenti
        .filter((p) => {
          return (
            p.codice_movimento !== TipiMovimenti.CONTABILITA_PAGAMENTO &&
            p.progressivo &&
            store.state.payments.payments_validator[p.progressivo] !== null
          )
        })
        .map((p) => p.importo_finale ?? 0)
        .reduce((t: number, p: number) => t + p, 0)
    }
    return (state.currentSale.importo_iniziale ?? 0) + totalPagamenti
  },
  [Getters.CHECK_FOR_ONE_CASH_PAYMENT]: (state: SalesState): boolean => {
    if (state.currentSale.pagamenti) {
      return state.currentSale.pagamenti.some(
        (p) => p.cod_operazione === 'CONTANTI',
      )
    }
    return false
  },
  [Getters.CHECK_FOR_ONE_SUSPENDED_CREDIT_PAYMENT]: (state: SalesState) => {
    if (state.currentSale.pagamenti) {
      return state.currentSale.pagamenti.some(
        (p) => p.cod_operazione === 'CREDITO_SOSPESO',
      )
    }
    return false
  },
  [Getters.GET_CURRENT_SALE]: (
    state: SalesState,
  ): ApiV1SalesDataAttributes | ApiV1SalesSaleIdDataAttributes =>
    state.currentSale,
  [Getters.GET_ANONYMOUS]: (state: SalesState): boolean => state.isAnonymous,
  [Getters.GET_SALE_IMPORTO_FINALE]: (state: SalesState): number =>
    state.currentSale.importo_finale ?? 0,
  [Getters.GET_CURRENT_CAPO_TAILORING]: (
    state: SalesState,
    getters: GetterTree<SalesState, RootState>,
    rootState: RootState,
  ) => {
    if (state.currentSale.pagamenti) {
      return state.currentSale.pagamenti.filter(
        (p) =>
          p.progressivo_capo === rootState.product.progressivo &&
          (p.codice_movimento === TipiMovimenti.CONTABILITA_SARTORIA ||
            p.codice_movimento === TipiMovimenti.CONTABILITA_SARTORIA_OMAGGIO),
      )
    }
    return []
  },
  [Getters.GET_PRODUCT]:
    (state: SalesState) =>
    (progressivo: number): GetSkuDataAttributes | null =>
      state.product_detail[progressivo] ?? null,
  [Getters.GET_CAPO]:
    (state: SalesState) =>
    (
      progressivo: number,
    ):
      | ApiV1SalesDataAttributesCapi
      | ApiV1SalesSaleIdDataAttributesCapi
      | null =>
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      state.currentSale.capi?.find((c) => c.progressivo === progressivo) ??
      null,
  [Getters.GET_CAPO_ALTERATION_TOTAL]:
    (state: SalesState) =>
    (progressivo: number): number | undefined => {
      return state.currentSale.pagamenti
        ?.filter(
          (p) =>
            p.progressivo_capo === progressivo &&
            (p.codice_movimento === TipiMovimenti.CONTABILITA_SARTORIA ||
              p.codice_movimento ===
                TipiMovimenti.CONTABILITA_SARTORIA_OMAGGIO),
        )
        .map((p) => p.importo_finale ?? 0)
        .reduce((t, i) => t + i, 0)
    },
  [Getters.GET_CAPO_ALTERATION]:
    (state: SalesState) =>
    (progressivo: number): ApiV1SalesDataAttributesPagamenti[] | undefined => {
      progressivo = progressivo || 0
      return state.currentSale.pagamenti?.filter(
        (p) =>
          p.progressivo_capo === progressivo &&
          (p.codice_movimento === TipiMovimenti.CONTABILITA_SARTORIA ||
            p.codice_movimento === TipiMovimenti.CONTABILITA_SARTORIA_OMAGGIO),
      )
    },
  [Getters.GET_CREDITO_SOSPESO_ENABLED]: (
    state: SalesState,
    getters: GetterTree<SalesState, RootState>,
    rootState: RootState,
  ) => {
    const updateDate = parse(
      state.consumer?.data_ultimo_aggiornamento || '',
      'yyyyMMdd',
      new Date(),
    )
    const refDate = subDays(
      new Date(),
      parseInt(rootState.configs.currentStore?.CONSUMER_OUTDATE_DAYS || '', 10),
    )
    const isBefore = compareAsc(updateDate, refDate) === -1
    const noPhone =
      !state.consumer?.telefono1 &&
      !state.consumer?.telefono2 &&
      !state.consumer?.cellulare1 &&
      !state.consumer?.cellulare2
    const noAddress = !state.consumer?.indirizzo || !state.consumer.localita
    return !isBefore && !noPhone && !noAddress
  },
  [Getters.GET_DOCUMENTO_SPEDIZIONE]: (
    state: SalesState,
  ): ShippingDetails | null => {
    if (state.currentSale.dati_documenti) {
      const parsedShippingDetails = JSON.parse(state.currentSale.dati_documenti)
      return {
        shipping_destination: parsedShippingDetails.shipping_destination,
        shipping_address: parsedShippingDetails.shipping_address,
      }
    }
    return null
  },
  [Getters.GET_CARDS_PAYMENT_IN_RETURNS_VALID]: (): boolean => {
    let areThereValidCardPaymentsInReturns
    const condition = (pagamento) => {
      return (
        pagamento.cod_operazione === PaymentsType.CARTA ||
        pagamento.cod_operazione === PaymentsType.GIFT_CARD_CORPORATE
      )
    }
    const salesPayments =
      store.state.sales?.currentSale?.pagamenti?.filter(condition) ?? []
    const payments =
      store.state.payments?.selectedPayments.filter(condition) ?? []
    const totalP = [...salesPayments, ...payments]
    if (totalP.length > 0) {
      areThereValidCardPaymentsInReturns = totalP.some((payment) => {
        const jsonObj = JSON.parse(payment?.dati_operazione || '{}')
        const esitoCarta = jsonObj?.esito
        const esitoGift = jsonObj?.Result || jsonObj?.dati_gift_card?.Result
        return esitoCarta === 'ESITO_POSITIVO' || esitoGift === 'OK'
      })
    } else {
      areThereValidCardPaymentsInReturns = false
    }
    return areThereValidCardPaymentsInReturns
  },
  [Getters.GET_CREDIT_CARD_PAYMENT_VALID]: (state: SalesState): boolean => {
    if (!state.currentSale.pagamenti?.length) {
      return false
    }
    const pay = state.currentSale.pagamenti.find(
      (p) => p.cod_operazione === 'CARTA',
    )
    return (
      !!pay &&
      !!pay.progressivo &&
      !!store.state.payments.payments_validator[pay.progressivo]
    )
  },
  [Getters.GET_GIFT_CARD_PAYMENT_VALID]: (state: SalesState): boolean => {
    if (!state.currentSale.pagamenti?.length) {
      return false
    }
    const pay = state.currentSale.pagamenti.find(
      (p) => p.cod_operazione === PaymentsType.GIFT_CARD_CORPORATE,
    )
    return (
      !!pay &&
      typeof pay.progressivo !== 'undefined' &&
      !!store.state.payments.payments_validator[pay.progressivo]
    )
  },
  [Getters.PURCHASE_IS_ABOVE_THRESHOLD]: (state: SalesState): boolean => {
    const getTotalPrice = store.getters[SalesGetters.GET_TOTAL_PRICE]
    const getPaymentsTotal = store.getters[SalesGetters.GET_PAYMENTS_TOTAL]
    const preChange = getTotalPrice - getPaymentsTotal
    const change = preChange < 0 ? Math.abs(preChange) : 0
    const importo = store.getters[SalesGetters.GET_ONLY_PAYMENTS].find(
      (p: ApiV1SalesDataAttributesPagamenti) => p.cod_operazione === 'CONTANTI',
    )?.importo_iniziale
    const cashPayableAmount = store.getters[
      SalesGetters.GET_CONSUMER_SAME_COUNTRY_CONDE
    ]
      ? store.getters[ConfigGetters.GET_MAX_PAYABLE_CASH_AMOUNT]
      : store.getters[ConfigGetters.GET_MAX_PAYABLE_CASH_AMOUNT_EXTRA_COUNTRY]

    const currentImport =
      (state.usedCash?.amount ?? 0) + parseFloat(importo) - change
    return currentImport >= cashPayableAmount
  },
  [Getters.GET_CONSUMER_SAME_COUNTRY_CONDE]: (): boolean => {
    const cashForm = store.getters[SalesGetters.GET_PAYMENT_DATA_CASH]
    const getNations = store.getters[GenericGetters.GET_NATIONS]
    const countryCode = store.getters[ConfigGetters.GET_COUNTRY_CODE]
    const getAnonymousConsumer =
      store.getters[ConsumersGetters.GET_ANONYMOUS_CONSUMER_LIST]
    const getAnonymousConsumerId = store.getters[SalesGetters.GET_ANONYMOUS_ID]
    const getConsumer = store.getters[SalesGetters.GET_CONSUMER]
    if (store.getters[SalesGetters.GET_ANONYMOUS]) {
      if (cashForm.nazioneDiResidenza) {
        const selectedNationInForm = getNations.find(
          (c: GetCountriesAttributes) =>
            c.nazione_iso === cashForm.nazioneDiResidenza,
        )
        return selectedNationInForm?.nazione_iso === countryCode
      }
      if (getAnonymousConsumerId !== AnonymousConsumers.SUPER_ANONYMOUS) {
        const anonymousConsumer = getAnonymousConsumer.find(
          (consumer: any) => consumer.pk_consumer === getAnonymousConsumerId,
        )
        if (anonymousConsumer) {
          const selectedNation = getNations.find(
            (nation: GetCountriesAttributes) =>
              nation.nazione_iso === anonymousConsumer.nation,
          )
          if (selectedNation) {
            return selectedNation?.nazione_iso === countryCode
          }
        }
      }

      const selectedStoreNation = getNations.find(
        (c: GetCountriesAttributes) => c.nazione_iso === countryCode,
      )
      return selectedStoreNation?.nazione_iso === countryCode
    }
    const selectedNationInForm = getNations.find(
      (c: GetCountriesAttributes) =>
        c.nazione_iso === cashForm.nazioneDiResidenza,
    )
    if (selectedNationInForm) {
      return selectedNationInForm?.nazione_iso === countryCode
    }
    return countryCode === getConsumer?.nazione_iso
  },
  [Getters.GET_COUPON]: (
    state: SalesState,
  ): ApiV1SalesSaleIdDataAttributesPagamenti | undefined => {
    if (!state.currentSale.pagamenti?.length) {
      return undefined
    }
    return (
      state.currentSale.pagamenti.find(
        (p) =>
          p.codice_movimento === TipiMovimenti.CONTABILITA_COUPON &&
          store.state.payments.payments_validator[String(p.progressivo)] !==
            false,
      ) ?? undefined
    )
  },
  [Getters.GET_IS_GIFT_CARD_SAVE_IN_PROCESS]: (state: SalesState): boolean => {
    return state.saveGiftCardInProcess
  },
  [Getters.GET_IS_GROUP_CONSUMER]: (state: SalesState): boolean => {
    return state.isGroupConsumer
  },
  /**
   * Getter that returns the status of the sale based on the stock type of the items in the sale.
   * The possible return values are:
   * - 1 `SaleStoreStatus.IN_STORE`: if all items are in-store
   * - 2 `SaleStoreStatus.OUT_STORE`: if all items are out-of-store (b2e articles)
   * - 3 `SaleStoreStatus.MIXED`: if the sale contains a mix of in-store and out-of-store items
   * @param state - The current state of the sales module
   * @returns The status of the sale based on the stock type of the items
   */
  [Getters.GET_SALE_STORE_STATUS]: (state: SalesState): SaleStoreStatus => {
    if (!state.currentSale?.capi?.length) {
      return SaleStoreStatus.IN_STORE
    }
    const outStoreCount =
      state.currentSale?.capi?.filter((c) => {
        if (c.custom_data) {
          const parsedData = JSON.parse(c.custom_data)
          return parsedData?.stock_type === 'OUT_STORE'
        }
        return null
      })?.length || 0
    if (!state.currentSale?.capi?.length) {
      return 0
    }
    if (outStoreCount === state.currentSale?.capi?.length) {
      return SaleStoreStatus.OUT_STORE
    } // Tutta out of store
    if (outStoreCount > 0) {
      return SaleStoreStatus.MIXED
    } // Mista
    return SaleStoreStatus.IN_STORE // tutta in store
  },
  [Getters.GET_CART_VIEW]: (state: SalesState): string => {
    return state.cartView || 'carousel'
  },
  [Getters.GET_DATA_VISIBILITY]: (
    state: SalesState,
  ): Statistics | undefined => {
    return <Statistics>state.consumerStatistics
  },
  [Getters.GET_IS_GIFT_CARD_SALE]: (state: SalesState): boolean => {
    return !!state.currentSale?.pagamenti?.find(
      (p) => p.codice_movimento === TipiMovimenti.CONTABILITA_GIFT,
    )
  },
  [Getters.GET_SUSPENDED_CREDIT]: (state: SalesState): SuspendedCreditForm => {
    return state.suspendedCredit
  },
  [Getters.GET_SALES_HEADER_ICONS]: (
    state: SalesState,
  ): GetConsumerExtraInfoDataAttributes => state.headerIcons,
  [Getters.GET_PAYMENT_DATA]: (state: SalesState): PaymentMethodForm => {
    return state.paymentData || ({} as PaymentMethodForm)
  },
  [Getters.GET_PAYMENT_DATA_CASH]: (
    state: SalesState,
  ): CashGenericPaymentMethodForm => {
    return state.paymentData.cashData || ({} as CashGenericPaymentMethodForm)
  },
  [Getters.GET_CART_TYPE]: (state: SalesState): CartType[] => {
    return state.cartType
  },
  [Getters.GET_MAX_IMPORT_CHEQUE]: (state: SalesState): number => {
    const { MAX_MONEY_AMOUNT, MAX_MONEY_AMOUNT_EXTRA_COUNTRY } =
      store.getters[ConfigGetters.GET_STORE_CONFIGS]
    return state.consumer?.nazionalita === 'IT'
      ? parseFloat(MAX_MONEY_AMOUNT)
      : parseFloat(MAX_MONEY_AMOUNT_EXTRA_COUNTRY)
  },
  [Getters.GET_SELECTED_CART_TYPE]: (state: SalesState): CartType => {
    return state.selectedCartType
  },

  [Getters.GET_TOTAL_PRICE_DEPOSIT]: (state: SalesState): number => {
    return state.totalPriceDeposit
  },
  [Getters.GET_TOTAL_PRICE_DEPOSIT_PAYMENT_METHOD]: (
    state: SalesState,
  ): number => {
    return state.totalPriceDepositPaymentMethod
  },

  [Getters.GET_USED_CASH]: (state: SalesState): UsedCash => state.usedCash,
  [Getters.GET_SUM_OF_DEBTPAYMENTS_VALIDATION_GIFTCARDSALE]: (
    state: SalesState,
  ): boolean => {
    const debtPayments = store.getters[SalesGetters.GET_ONLY_PAYMENTS].filter(
      (payment) =>
        payment.cod_operazione === PaymentsType.CREDITO_SOSPESO ||
        payment.cod_operazione === PaymentsType.BUONO_GIFT_CARD ||
        payment.cod_operazione === PaymentsType.GIFT_CARD_CORPORATE,
    )
    const debtPaymentsTotal = debtPayments.reduce(
      (totalSum, currentPayment) => {
        return totalSum + currentPayment.importo_finale
      },
      0,
    )
    const giftCardSale = state.currentSale.pagamenti?.filter(
      (sale) => sale.cod_operazione === CostiExtraType.GIFT_CARD,
    )

    const giftCardSaleTotal = giftCardSale?.reduce((totalSum, currentSale) => {
      return totalSum + (currentSale?.importo_finale ?? 0)
    }, 0)
    const saleWithoutDebtPayment =
      store.getters[SalesGetters.GET_CURRENT_SALE].importo_finale -
      giftCardSaleTotal
    if (giftCardSaleTotal === 0) {
      return false
    }
    return debtPaymentsTotal > (saleWithoutDebtPayment ?? 0)
  },
  [Getters.IS_FREE_RETURN]: (state: SalesState): boolean => {
    const returnPagamenti =
      state.currentSale.pagamenti?.some((c) => {
        if (c.dati_operazione && Object.keys(c.dati_operazione).length > 0) {
          const cod_mov = JSON.parse(c.dati_operazione ?? '{}')?.cod_movimento
          return cod_mov === TipiVendita.STORNO || cod_mov === TipiVendita.RESO
        }
      }) || false
    const returnCapi =
      state.currentSale.capi?.some(
        (c) =>
          c.codice_movimento === TipiVendita.STORNO ||
          c.codice_movimento === TipiVendita.RESO,
      ) || false
    return returnPagamenti || returnCapi
  },
  [Getters.IS_ALL_FREE_RETURN]: (state: SalesState): boolean => {
    const allowedType = [
      TipiVendita.STORNO,
      TipiVendita.RESO,
      TipiVendita.SCARICO_OMAGGIO,
    ]
    return (
      state.currentSale.capi?.every((c) =>
        allowedType.includes(c.codice_movimento as TipiVendita),
      ) || false
    )
  },
  [Getters.IS_ONLY_TAILORING_ALTERATION_FREE_SALE]: (state: SalesState) => {
    return (
      state.currentSale.pagamenti &&
      state.currentSale.capi?.length === 0 &&
      state.currentSale.pagamenti.filter(
        (p) =>
          p.codice_movimento === TipiMovimenti.CONTABILITA_SARTORIA_OMAGGIO,
      ).length === state.currentSale.pagamenti.length
    )
  },
  [Getters.IS_ONLY_FREE_SALE]: (state: SalesState) => {
    return (
      state.currentSale.capi?.length &&
      state.currentSale.pagamenti?.length === 0 &&
      state.currentSale.capi.every(
        (p) => p.codice_movimento === TipiVendita.SCARICO_OMAGGIO,
      )
    )
  },
  [Getters.IS_OSS_SALE]: (state: SalesState) => {
    // ideally we should get rid of this logic and use a dedicated property of the currentSale (e.g. is_oss_sale) for a more efficient check

    let datiDocumenti =
      JSON.parse(state.currentSale?.dati_documenti || '{}') || {}
    let shippingAddress = datiDocumenti?.shipping_address || {}
    const storeCountryCode = store.getters[ConfigGetters.GET_COUNTRY_CODE]
    const isShippingAddressCountryInEU = (address: ShippingAddress) => {
      const countryIso = address?.country
      const selectedNation = store.getters[GenericGetters.GET_NATIONS].find(
        (nation: GetCountriesAttributes) => nation.nazione_iso === countryIso,
      )
      return selectedNation?.flag_ue
    }
    return !!(
      isConfigTruthy('OSS_ENABLED') &&
      !state.isAnonymous &&
      Object.keys(datiDocumenti)?.length > 0 &&
      Object.keys(shippingAddress)?.length > 0 &&
      shippingAddress?.country !== storeCountryCode &&
      isShippingAddressCountryInEU(shippingAddress)
    )
  },
  [Getters.GET_CODICE_MOVIMENTO]: (state: SalesState): string | undefined =>
    state.currentSale.codice_movimento,
  [Getters.GET_DATI_STORNO]: (state: SalesState): datiStornoD =>
    state.dati_storno,
  [Getters.GET_DISABLED_CONFIRM]: (state: SalesState): boolean =>
    state.disabledConfirm,
  [Getters.GET_CAN_CHANGE_CART_TYPE]: (state: SalesState): CanChangeCartTypeD =>
    state.canChangeCartType,
  [Getters.GET_ALL_PRODUCT]: (state: SalesState): GetSkuDataAttributes | null =>
    state.product_detail ?? null,
  [Getters.GET_INFO_GIACENZA_CAPI]: (state: SalesState): IntoGiacenzaCapiT =>
    state.infoGiacenzaCapi,
  [Getters.GET_QUANTITY_INFO_GIACENZA_CAPI]:
    (state: SalesState): ((sku: string) => number) =>
    (sku: string) =>
      state.currentSale?.capi?.filter((c) => c.sku === sku).length || 0,
  [Getters.GET_DOCUMENTO_SPEDIZIONE_CONSUMER]: (
    state: SalesState,
  ): ShippingDetails | null => {
    if (state.currentSale.dati_documenti) {
      const parsedShippingDetails = JSON.parse(state.currentSale.dati_documenti)
      const updatedShippingAddress = {
        ...parsedShippingDetails.shipping_address,
        reference_name:
          parsedShippingDetails.shipping_address?.consumer_info
            ?.reference_name ??
          parsedShippingDetails.shipping_address?.reference_name ??
          '',
        contact_number:
          parsedShippingDetails.shipping_address?.contact_number ||
          store.state.consumers?.currentConsumer?.telefono1 ||
          store.state.consumers?.currentConsumer?.telefono2 ||
          store.state.consumers?.currentConsumer?.cellulare1 ||
          store.state.consumers?.currentConsumer?.cellulare2 ||
          '',
        contact_email:
          parsedShippingDetails.shipping_address?.contact_email ||
          store.state.consumers?.currentConsumer?.email ||
          '',
        country:
          parsedShippingDetails.shipping_address?.country ||
          store.state.consumers?.currentConsumer?.nazione_iso ||
          '',
        address:
          parsedShippingDetails.shipping_address?.address ||
          store.state.consumers?.currentConsumer?.indirizzo ||
          '',
        contact_note:
          parsedShippingDetails.shipping_address?.address_notes || '',
        street_number:
          parsedShippingDetails.shipping_address?.street_number ||
          store.state.consumers?.currentConsumer?.numero_civico ||
          '',
        city:
          parsedShippingDetails.shipping_address?.city ||
          store.state.consumers?.currentConsumer?.localita ||
          '',
        zipcode:
          parsedShippingDetails.shipping_address?.zipcode ||
          store.state.consumers?.currentConsumer?.cap ||
          '',
        province:
          parsedShippingDetails.shipping_address?.province ||
          store.state.consumers?.currentConsumer?.provincia ||
          '',
        building:
          parsedShippingDetails.shipping_address?.building ||
          store.state.consumers?.currentConsumer?.edificio ||
          '',
        name:
          parsedShippingDetails.shipping_address?.name ||
          store.state.consumers?.currentConsumer?.nome ||
          '',
        surname:
          parsedShippingDetails.shipping_address?.surname ||
          store.state.consumers?.currentConsumer?.cognome ||
          '',
        state: parsedShippingDetails.shipping_address?.state || '',
      }
      return {
        ...parsedShippingDetails,
        shipping_address: updatedShippingAddress,
      }
    }
    return null
  },
  [Getters.GET_DOCUMENTO_SPEDIZIONE_STORE]: (
    state: SalesState,
  ): ShippingDetails | null => {
    if (state.currentSale.dati_documenti) {
      const parsedShippingDetails = JSON.parse(state.currentSale.dati_documenti)
      const getCurrentStore = store.getters[ConfigGetters.GET_STORE_CONFIGS]
      let storeData = null
      if (getCurrentStore?.store_data) {
        storeData =
          getCurrentStore.store_data[
            store.getters[ConfigGetters.GET_STORE_CONFIGS]?.STORE_CODE
          ] || {}
      }
      const updatedShippingAddress = {
        ...parsedShippingDetails.shipping_address,

        reference_name:
          parsedShippingDetails.shipping_address?.consumer_info
            ?.reference_name ??
          parsedShippingDetails.shipping_address?.reference_name ??
          '',
        contact_number:
          parsedShippingDetails.shipping_address?.consumer_info
            ?.contact_number ||
          parsedShippingDetails.shipping_address?.contact_number ||
          storeData?.telefono ||
          store.state.consumers?.currentConsumer?.telefono1 ||
          store.state.consumers?.currentConsumer?.telefono2 ||
          store.state.consumers?.currentConsumer?.cellulare1 ||
          store.state.consumers?.currentConsumer?.cellulare2 ||
          '',
        contact_email:
          parsedShippingDetails.shipping_address?.consumer_info
            ?.contact_email ||
          parsedShippingDetails.shipping_address?.contact_email ||
          store.state.consumers?.currentConsumer?.email ||
          '',
        country:
          parsedShippingDetails.shipping_address?.country ||
          storeData?.sigla_nazione ||
          '',
        address:
          parsedShippingDetails.shipping_address?.address ||
          storeData?.indirizzo ||
          '',
        city:
          parsedShippingDetails.shipping_address?.city ||
          storeData?.citta ||
          '',
        zipcode:
          parsedShippingDetails.shipping_address?.zipcode ||
          storeData?.cap ||
          '',
        province:
          parsedShippingDetails.shipping_address?.province ||
          storeData?.provincia ||
          '',
        name:
          parsedShippingDetails.shipping_address?.consumer_info?.name ||
          parsedShippingDetails.shipping_address?.name ||
          store.state.consumers?.currentConsumer?.nome ||
          '',
        surname:
          parsedShippingDetails.shipping_address?.consumer_info?.surname ||
          parsedShippingDetails.shipping_address?.surname ||
          store.state.consumers?.currentConsumer?.cognome ||
          '',
      }
      return {
        ...parsedShippingDetails,
        shipping_address: updatedShippingAddress,
      }
    }
    return null
  },
}
