import { GetterTree } from 'vuex'
import { RootState } from '@/store'
import { State } from './_state'

export enum GetterNames {
  GET_STATUS_CHECK = 'get-status-check',
  GET_SELECTED_ADDRESS = 'get-selected-address',
  GET_STATUS_CHECK_CONSUMER = 'get-status-check-consumer',
  GET_STATUS_CHECK_STORE = 'get-status-check-store',
  GET_SELECTED_ADDRESS_CONSUMER = 'get-selected-address-consumer',
  GET_SELECTED_ADDRESS_STORE = 'get-selected-address-store',
  GET_ADDRESS_TYPE = 'get-address-type',
}

export type Getters = {
  [GetterNames.GET_STATUS_CHECK](
    state: State,
    rootState: RootState,
  ): State['statusValidateCheck']
  [GetterNames.GET_SELECTED_ADDRESS](
    state: State,
    rootState: RootState,
  ): State['selectedAddress']
  [GetterNames.GET_STATUS_CHECK_CONSUMER](
    state: State,
    rootState: RootState,
  ): State['statusValidateCheckConsumer']
  [GetterNames.GET_STATUS_CHECK_STORE](
    state: State,
    rootState: RootState,
  ): State['statusValidateCheckStore']
  [GetterNames.GET_SELECTED_ADDRESS_STORE](
    state: State,
    rootState: RootState,
  ): State['selectedAddressStore']
  [GetterNames.GET_ADDRESS_TYPE](
    state: State,
    rootState: RootState,
  ): State['addressTypeStore']
}

export const getters: GetterTree<State, RootState> = {
  [GetterNames.GET_STATUS_CHECK]: (
    state: State,
  ): State['statusValidateCheck'] => state.statusValidateCheck,
  [GetterNames.GET_SELECTED_ADDRESS]: (
    state: State,
  ): State['selectedAddress'] => state.selectedAddress,
  [GetterNames.GET_STATUS_CHECK_CONSUMER]: (
    state: State,
  ): State['statusValidateCheckConsumer'] => state.statusValidateCheckConsumer,
  [GetterNames.GET_STATUS_CHECK_STORE]: (
    state: State,
  ): State['statusValidateCheckStore'] => state.statusValidateCheckStore,
  [GetterNames.GET_SELECTED_ADDRESS_CONSUMER]: (
    state: State,
  ): State['selectedAddressConsumer'] => state.selectedAddressConsumer,
  [GetterNames.GET_SELECTED_ADDRESS_STORE]: (
    state: State,
  ): State['selectedAddressStore'] => state.selectedAddressStore,
  [GetterNames.GET_ADDRESS_TYPE]: (state: State): string =>
    state.addressTypeStore,
}
