import { MutationTree } from 'vuex'
import { State, initState } from './_state'
import { DeliveryDataD } from '@/components/drawer/extra-services/delivery-section/delivery-section.d'
export enum MutationsNames {
  SET_STATUS_CHECK = 'set-status-check',
  SET_SELECTED_ADDRESS = 'set-selected-address',
  RESET_ADDRESS_VALIDATION = 'reset-address-validation',
  SET_STATUS_CHECK_CONSUMER = 'set-status-check_consumer',
  SET_STATUS_CHECK_STORE = 'set-status-check_store',
  SET_SELECTED_ADDRESS_CONSUMER = 'set-selected-address-consumer',
  SET_SELECTED_ADDRESS_STORE = 'set-selected-address-store',
  SET_ADDRESS_TYPE = 'set-address-type',
  RESET_ADDRESS_VALIDATION_SHIPPING = 'reset-address-validation-shipping',
}

export type Mutations = {
  [MutationsNames.SET_STATUS_CHECK](payload: State['statusValidateCheck']): any
  [MutationsNames.SET_SELECTED_ADDRESS](payload: DeliveryDataD): void
  [MutationsNames.SET_STATUS_CHECK_CONSUMER](
    payload: State['statusValidateCheckConsumer'],
  ): any
  [MutationsNames.SET_STATUS_CHECK_STORE](
    payload: State['statusValidateCheckStore'],
  ): any
  [MutationsNames.SET_SELECTED_ADDRESS_CONSUMER](payload: DeliveryDataD): void
  [MutationsNames.SET_SELECTED_ADDRESS_STORE](payload: DeliveryDataD): void
  [MutationsNames.SET_ADDRESS_TYPE](payload: string): any
}
export const mutations: MutationTree<State> = {
  [MutationsNames.SET_STATUS_CHECK](
    state: State,
    payload: State['statusValidateCheck'],
  ) {
    state.statusValidateCheck = Object.assign(
      {},
      state.statusValidateCheck,
      payload,
    )
  },
  [MutationsNames.SET_SELECTED_ADDRESS](state: State, payload: DeliveryDataD) {
    state.selectedAddress = payload
  },
  [MutationsNames.RESET_ADDRESS_VALIDATION](state: State) {
    state.selectedAddress = initState().selectedAddress
    state.statusValidateCheck = initState().statusValidateCheck
  },
  [MutationsNames.SET_STATUS_CHECK_CONSUMER](
    state: State,
    payload: State['statusValidateCheckConsumer'],
  ) {
    state.statusValidateCheckConsumer = Object.assign(
      {},
      state.statusValidateCheckConsumer,
      payload,
    )
  },
  [MutationsNames.SET_STATUS_CHECK_STORE](
    state: State,
    payload: State['statusValidateCheckStore'],
  ) {
    state.statusValidateCheckStore = Object.assign(
      {},
      state.statusValidateCheckStore,
      payload,
    )
  },
  [MutationsNames.SET_SELECTED_ADDRESS_CONSUMER](
    state: State,
    payload: DeliveryDataD,
  ) {
    state.selectedAddressConsumer = payload
  },
  [MutationsNames.SET_SELECTED_ADDRESS_STORE](
    state: State,
    payload: DeliveryDataD,
  ) {
    state.selectedAddressStore = payload
  },
  [MutationsNames.SET_ADDRESS_TYPE](state: State, payload: string) {
    state.addressTypeStore = payload
  },
  [MutationsNames.RESET_ADDRESS_VALIDATION_SHIPPING](state: State) {
    state.selectedAddressConsumer = initState().selectedAddressConsumer
    state.statusValidateCheckConsumer = initState().statusValidateCheckConsumer
    state.selectedAddressStore = initState().selectedAddressStore
    state.statusValidateCheckStore = initState().statusValidateCheckStore
  },
}
