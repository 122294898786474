import store from '@/store'
import {
  ApiV1SalesDataAttributes,
  ApiV1SalesDataAttributesCapi,
  ApiV1SalesDataAttributesPagamenti,
  ApiV1SalesSaleIdDataAttributes,
  ApiV1SalesSaleIdDataAttributesCapi,
  ApiV1SalesSaleIdDataAttributesPagamenti,
  GetCashiersAttributes,
  GetConsumerExtraInfoDataAttributes,
  GetSkuDataAttributes,
} from '@/api'
import {
  initDatiStorno,
  initState,
  SalesActions,
  SalesGetters,
} from './sales-store'
import {
  CanChangeCartTypeD,
  CartType,
  datiStornoD,
  InfoGiacenzaCapiD,
  modalCreditCardInstanceType,
  Mutations,
  SalesState,
  Statistics,
  StoreConsumerPayload,
  UsedCash,
} from './sales-types'
import {
  CodiceStatoVendita,
  PaymentKeys,
  StockIcon,
  TipiMovimenti,
  TipiVendita,
} from '@/addons/enums'
import { SuspendedCreditForm } from '@/components/sales/payments/payment-suspended-credit/suspendedCredit'
import {
  CashGenericPaymentMethodForm,
  ChequeGenericPaymentMethodForm,
} from '@/interfaces/payment-method-form'
import { stockIcon } from '@/addons/functions/sales'

export const salesMutations = {
  [Mutations.SET_MODAL_CREDIT_CARD_INSTANCE]: (
    state: SalesState,
    payload: modalCreditCardInstanceType,
  ): void => {
    state.modalCreditCardInstance = payload
  },
  [Mutations.RESET_STATE]: (state: SalesState): void => {
    Object.assign(state, initState())
  },
  [Mutations.RESET_DATI_STORNO]: (state: SalesState): void => {
    Object.assign(state.dati_storno, initDatiStorno())
  },
  [Mutations.CHECK_ANTIRICICLAGGIO_RESULT]: (
    state: SalesState,
    payload: boolean,
  ): void => {
    state.checkAntireciclaggioResult = payload
  },
  [Mutations.STORE_SALE]: (
    state: SalesState,
    sale: ApiV1SalesDataAttributes | ApiV1SalesSaleIdDataAttributes,
  ): void => {
    Object.assign(state.currentSale, sale)
  },
  [Mutations.CLEAR_SALE]: (state: SalesState): void => {
    state.currentSale = {}
  },
  [Mutations.STORE_SALE_CAPI]: (
    state: SalesState,
    capi: ApiV1SalesDataAttributesCapi[] | ApiV1SalesSaleIdDataAttributesCapi[],
  ): void => {
    state.currentSale.capi = capi
  },
  [Mutations.CLEAR_SALE_CAPI]: (state: SalesState): void => {
    state.currentSale.capi = []
  },
  [Mutations.STORE_SALE_PAGAMENTI]: (
    state: SalesState,
    pagamenti:
      | ApiV1SalesDataAttributesPagamenti[]
      | ApiV1SalesSaleIdDataAttributesPagamenti[],
  ): void => {
    state.currentSale.pagamenti = pagamenti
  },
  [Mutations.CLEAR_SALE_PAGAMENTI]: (state: SalesState): void => {
    state.currentSale.pagamenti = []
  },
  [Mutations.CLEAR_ONLY_PAYMENTS_TYPE]: (state: SalesState): void => {
    state.currentSale.pagamenti = state.currentSale.pagamenti?.filter((p) => {
      return p.codice_movimento !== TipiMovimenti.CONTABILITA_PAGAMENTO
    })
  },
  [Mutations.STORE_SALE_IMPORTO_FINALE]: (
    state: SalesState,
    importo: number,
  ): void => {
    state.currentSale.importo_finale = importo
  },
  [Mutations.STORE_CONSUMER]: (
    state: SalesState,
    payload: StoreConsumerPayload,
  ): void => {
    if (typeof payload.groupConsumer === 'undefined') {
      payload.groupConsumer = false
    }
    state.currentSale.pk_consumer =
      (payload.consumer?.pk_consumer as string) ?? null

    if (payload.consumer === null) {
      delete state.consumer
    } else {
      try {
        state.consumer = JSON.parse(JSON.stringify(payload?.consumer))
      } catch {
        state.consumer = {}
      }
    }

    state.isGroupConsumer = payload.groupConsumer
  },
  [Mutations.CLEAR_SALE_IMPORTO_FINALE]: (state: SalesState): void => {
    state.currentSale.importo_finale = 0
  },
  [Mutations.STORE_SALE_IMPORTO_PAGATO]: (
    state: SalesState,
    importo: number,
  ): void => {
    state.currentSale.importo_pagato = importo
  },
  [Mutations.CLEAR_SALE_IMPORTO_PAGATO]: (state: SalesState): void => {
    state.currentSale.importo_pagato = 0
  },
  [Mutations.STORE_PRODUCTS]: (
    state: SalesState,
    products: { [key: string]: GetSkuDataAttributes },
  ): void => {
    state.product_detail = products
  },
  [Mutations.CLEAR_PRODUCTS]: (state: SalesState): void => {
    state.product_detail = {}
  },
  [Mutations.STORE_PROGRESSIVO_CAPO]: (
    state: SalesState,
    progressivo: number,
  ): void => {
    state.progressivo_capi = progressivo
  },
  [Mutations.CLEAR_PROGRESSIVO_CAPO]: (state: SalesState): void => {
    state.progressivo_capi = 0
  },
  [Mutations.STORE_PROGRESSIVO_PAGAMENTI]: (
    state: SalesState,
    progressivo: number,
  ): void => {
    state.progressivo_pagamenti = progressivo
  },
  [Mutations.CLEAR_PROGRESSIVO_PAGAMENTI]: (state: SalesState): void => {
    state.progressivo_pagamenti = 0
  },
  [Mutations.CLEAR_CONSUMER]: (state: SalesState): void => {
    state.consumer = undefined
    state.isGroupConsumer = false
  },
  [Mutations.STORE_ANONYMOUS_FLAG]: (
    state: SalesState,
    flag: boolean,
  ): void => {
    state.isAnonymous = flag
  },
  [Mutations.CLEAR_ANONYMOUS_FLAG]: (state: SalesState): void => {
    state.isAnonymous = false
  },
  [Mutations.STORE_RDV]: (
    state: SalesState,
    rdv: GetCashiersAttributes,
  ): void => {
    state.selected_rdv = rdv
  },
  [Mutations.CLEAR_RDV]: (state: SalesState): void => {
    state.selected_rdv = undefined
  },
  [Mutations.STORE_CASHIER]: (
    state: SalesState,
    cashier: GetCashiersAttributes,
  ): void => {
    state.selected_cashier = cashier
  },
  [Mutations.CLEAR_CASHIER]: (state: SalesState): void => {
    state.selected_cashier = undefined
    state.currentSale.cod_cassiera = undefined
  },
  [Mutations.UPDATE_PRODUCT_DETAIL]: (
    state: SalesState,
    capo: ApiV1SalesDataAttributesCapi,
  ): void => {
    if (capo?.progressivo) {
      state.product_detail[capo.progressivo] = {
        ...state.product_detail[capo.progressivo],
        ...capo,
      }
    }
  },
  [Mutations.STORE_SKU_ALERT_OPEN]: (
    state: SalesState,
    flag: boolean,
  ): void => {
    state.skuAlertOpen = flag
  },
  [Mutations.STORE_CREATION_DATE]: (state: SalesState, date: string): void => {
    state.currentSale.data_creazione = date
  },
  [Mutations.UPDATE_SALE_STATUS]: (
    state: SalesState,
    status: CodiceStatoVendita,
  ): void => {
    state.currentSale.codice_stato = status
    state.currentSale.capi?.forEach((c) => {
      c.codice_stato = status
    })
  },
  [Mutations.STORE_ID_TRANSAZIONE]: (
    state: SalesState,
    saleId: number | string,
  ): void => {
    const id = typeof saleId === 'number' ? saleId : parseInt(saleId, 10)
    state.currentSale.id_transazione = id
    state.currentSale.capi?.forEach((c) => {
      c.id_transazione = id
    })
    if (state.currentSale.pagamenti) {
      state.currentSale.pagamenti?.forEach((c) => {
        c.id_transazione = id
      })
    }
  },
  [Mutations.CLEAR_ID_TRANSAZIONE]: (state: SalesState): void => {
    const id = undefined
    state.currentSale.id_transazione = id
    state.currentSale.capi?.forEach((c) => {
      c.id_transazione = id
    })
    if (state.currentSale.pagamenti) {
      state.currentSale.pagamenti?.forEach((c) => {
        c.id_transazione = id
      })
    }
  },
  [Mutations.STORE_CART_VIEW]: (state: SalesState, view: string): void => {
    state.cartView = view
  },
  [Mutations.STORE_DATA_VISIBILITY]: (
    state: SalesState,
    payload: Statistics,
  ): void => {
    state.consumerStatistics = payload
  },
  [Mutations.UPDATE_SUSPENDED_CREDIT]: (
    state: SalesState,
    payload: SuspendedCreditForm,
  ): void => {
    state.suspendedCredit = payload
  },
  [Mutations.SET_SALES_HEADER_ICONS]: (
    state: SalesState,
    headerIcons: GetConsumerExtraInfoDataAttributes,
  ) => (state.headerIcons = headerIcons),
  [Mutations.UPDATE_PAYMENT_DATA]: (
    state: SalesState,
    payload: { changed: { id: string; value: string }; paymentKey: string },
  ): void => {
    if (payload.paymentKey === PaymentKeys.CHEQUE) {
      Object.assign(state.paymentData.chequeData, {
        [payload.changed.id]: payload.changed.value,
      })
    } else if (payload.paymentKey === PaymentKeys.CASH) {
      Object.assign(state.paymentData.cashData, {
        [payload.changed.id]: payload.changed.value,
      })
    }
  },
  [Mutations.UPDATE_FULL_PAYMENT_DATA]: (
    state: SalesState,
    payload: {
      paymentForm: CashGenericPaymentMethodForm | ChequeGenericPaymentMethodForm
      paymentKey: string
    },
  ): void => {
    if (payload.paymentKey === 'cheque') {
      state.paymentData.chequeData = payload.paymentForm
    }
    if (payload.paymentKey === 'cash') {
      state.paymentData.cashData = payload.paymentForm
    }
    state.checkAntireciclaggioResult = false
  },
  [Mutations.SET_CART_TYPE]: (state: SalesState, payload: CartType[]): void => {
    state.cartType = payload
  },
  [Mutations.SET_SELECTED_CART_TYPE]: (
    state: SalesState,
    payload: CartType,
  ): void => {
    state.selectedCartType = payload
  },
  [Mutations.RESET_CART_TYPE]: (state: SalesState): void => {
    state.selectedCartType = initState().cartType[0]
  },

  [Mutations.SET_TOTAL_PRICE_DEPOSIT]: (
    state: SalesState,
    payload: {
      value: number
    },
  ): void => {
    state.totalPriceDeposit = payload.value
  },
  [Mutations.SET_TOTAL_PRICE_DEPOSIT_PAYMENT_METHOD]: (
    state: SalesState,
    payload: {
      value: number
    },
  ): void => {
    state.totalPriceDepositPaymentMethod = payload.value
  },

  [Mutations.SET_GIFT_CARD_SAVE_IN_PROCESS]: (
    state: SalesState,
    payload: boolean,
  ): void => {
    state.saveGiftCardInProcess = payload
  },
  [Mutations.SET_GIFT_RECEIPT]: (state: SalesState, payload: 1 | 0): void => {
    state.currentSale.flag_stampa_scontrino_cortesia = payload
  },

  [Mutations.SET_USED_CASH]: (state: SalesState, payload: UsedCash): void => {
    state.usedCash = payload
  },
  [Mutations.CLEAR_USED_CASH]: (state: SalesState): void => {
    state.usedCash = {}
  },
  [Mutations.SET_CODICE_MOVIMENTO]: (
    state: SalesState,
    payload: TipiVendita | undefined,
  ): void => {
    if (payload) {
      state.currentSale.codice_movimento = payload
    } else {
      state.currentSale.codice_movimento = TipiVendita.VENDITA
    }
  },
  [Mutations.SET_DATI_STORNO]: (
    state: SalesState,
    payload: datiStornoD,
  ): void => {
    state.dati_storno = Object.assign({}, state.dati_storno, payload)
  },
  [Mutations.SET_DATI_STORNO_CURRENTSALE]: (
    state: SalesState,
    payload: datiStornoD,
  ): void => {
    try {
      const dati_doc = JSON.parse(state.currentSale.dati_documenti)
      state.currentSale.dati_documenti = JSON.stringify({
        ...dati_doc,
        dati_storno: payload,
      })
    } catch (e) {
      // TODO: Gestire le casistiche di errore
    }
  },
  [Mutations.SET_TOTAL_CAPI]: (state: SalesState): void => {
    state.currentSale.capi?.map((c) => {
      c.importo_finale =
        c.codice_movimento === TipiVendita.STORNO ||
        c.codice_movimento === TipiVendita.RESO
          ? Math.abs(c.importo_finale) * -1
          : Math.abs(c.importo_finale)
      c.importo_iniziale = Math.abs(c.importo_iniziale)
      // TODO: STAB: va' modificato solo importo finale, importo iniziale non deve variare
      // DOC: FIX: è stato' modificato solo importo finale, importo iniziale non deve variare FGABS-2244
      return {
        ...c,
      }
    })
  },
  [Mutations.SET_DISABLED_CONFIRM]: (
    state: SalesState,
    payload: boolean,
  ): void => {
    state.disabledConfirm = payload
  },

  [Mutations.SET_CAN_CHANGE_CART_TYPE]: (
    state: SalesState,
    payload: CanChangeCartTypeD, // boolean,
  ): void => {
    state.canChangeCartType = payload
  },
  [Mutations.CHECK_PRODUCTS_STOCK]: (state: SalesState): void => {
    const currentStock = []
    const disallowdStockConfirm = [StockIcon.NOLISTDATA, StockIcon.NODATASTOCK]
    state.currentSale?.capi?.forEach((capo) => {
      const st = stockIcon(capo)
      if (disallowdStockConfirm.includes(<StockIcon>st)) {
        currentStock.push(st)
        store.dispatch(SalesActions.SET_CAN_CHANGE_CART_TYPE, {
          value: false,
          message: 'pos_stock.no_change_cart_type',
        })
      } else {
        store.dispatch(SalesActions.SET_CAN_CHANGE_CART_TYPE, {
          value: true,
          message: '',
        })
      }
    })
    store.dispatch(SalesActions.SET_DISABLED_CONFIRM, currentStock.length > 0)
  },

  [Mutations.UPDATE_SALE_DATA]: (state: SalesState, payload: any): void => {
    state.currentSale = payload
  },
  [Mutations.SET_INFO_GIACENZA_CAPI]: (
    state: SalesState,
    payload: InfoGiacenzaCapiD,
  ): void => {
    const currentItem = state.infoGiacenzaCapi[payload.sku]
    if (!currentItem) {
      state.infoGiacenzaCapi[payload.sku] = payload
    } else {
      state.infoGiacenzaCapi[payload.sku] = Object.assign({}, currentItem, {
        quantity: store.getters[SalesGetters.GET_QUANTITY_INFO_GIACENZA_CAPI](
          payload.sku,
        ),
      })
    }
  },
  [Mutations.REMOVE_INFO_GIACENZA_CAPI]: (
    state: SalesState,
    payload: string,
  ): void => {
    const currentItem = state.infoGiacenzaCapi[payload]
    if (currentItem) {
      if (currentItem.quantity === 1) {
        delete state.infoGiacenzaCapi[payload]
      } else {
        state.infoGiacenzaCapi[payload] = Object.assign({}, currentItem, {
          quantity:
            store.getters[SalesGetters.GET_QUANTITY_INFO_GIACENZA_CAPI](
              payload,
            ),
        })
      }
    }
  },
  [Mutations.RESET_INFO_GIACENZA_CAPI]: (state: SalesState): void => {
    state.infoGiacenzaCapi = {}
  },
  [Mutations.ADD_COD_COMMESSA]: (state: SalesState, payload: string): void => {
    state.currentSale.capi = state.currentSale.capi?.map((c) =>
      Object.assign({}, c, {
        cod_commessa: c.cod_commessa ? c.cod_commessa : payload || '',
      }),
    )
  },
}
