import { LaybyCartTypeID, LaybyStatusType } from '@/addons/enums'
import {
  ApiV1SalesDataAttributes,
  ApiV1SalesSaleIdDataAttributes,
  GetCashiersAttributes,
  GetConsumerAttributes,
  GetConsumerExtraInfoDataAttributes,
  GetSkuDataAttributes,
  InlineResponse200,
  InlineResponse2001,
  PostConsumerResponseDataAttributes,
} from '@/api'
import { SuspendedCreditForm } from '@/components/sales/payments/payment-suspended-credit/suspendedCredit'
import { PaymentMethodForm } from '@/interfaces/payment-method-form'
import { LaybyElement } from '../layby-store'
import { LaybyConsumerList, LaybyInfo } from './layby/sales-lb-types'

export interface UsedCash {
  amount?: number
  payable_amount?: number
}

export interface CanChangeCartTypeD {
  value: boolean
  message: string
}

export interface DiscountData {
  data: string
  discountActive: boolean
}

export interface InfoGiacenzaCapiD {
  sku: string
  quantity: number
  stock: number
  is_promo: boolean
  giac_offline: boolean
  stock_type?: string
}

export type modalCreditCardInstanceType = {
  confirm(): void
  close(): void
}
export interface SalesState {
  currentSale: ApiV1SalesDataAttributes | ApiV1SalesSaleIdDataAttributes
  progressivo_capi: number
  product_detail: { [key: string]: GetSkuDataAttributes }
  progressivo_pagamenti: number
  consumer?: GetConsumerAttributes
  isGroupConsumer: boolean
  isAnonymous: boolean
  selected_cashier?: GetCashiersAttributes
  selected_rdv?: GetCashiersAttributes
  skuAlertOpen?: boolean
  cartView?: string
  consumerStatistics?: Statistics
  suspendedCredit: SuspendedCreditForm
  headerIcons: GetConsumerExtraInfoDataAttributes
  paymentData: PaymentMethodForm
  cartType: CartType[]
  maxImportCheque: string
  selectedCartType: CartType
  totalPriceDeposit: number
  totalPriceDepositPaymentMethod: number
  laybyConsumerList: LaybyConsumerList[]
  laybyResumeData: LaybyElement
  laybyDepositValid: boolean
  saveGiftCardInProcess: boolean
  usedCash: UsedCash
  modalCreditCardInstance: modalCreditCardInstanceType | undefined
  dati_storno: datiStornoD
  disabledConfirm: boolean
  canChangeCartType: CanChangeCartTypeD
  checkAntireciclaggioResult: boolean
  pk_consumer: string
  infoGiacenzaCapi: IntoGiacenzaCapiT
  // LAYBY
  laybyInfo: LaybyInfo
  laybyStatus: LaybyStatusType
}

export type IntoGiacenzaCapiT = {
  [sku: string]: InfoGiacenzaCapiD
}
export interface datiStornoD {
  num_bollettina: string
  data_bollettina: string
  possiede_scontrino: 0 | 1
  original_sale_type: 'SALE' | 'SALE_B2E'
}

export interface CartType {
  id?: LaybyCartTypeID
  value?: string
  label?: string
}

export interface ShippingDetails {
  shipping_destination: string
  shipping_address?: ShippingAddress
}

export interface Statistics {
  statistics: InlineResponse200
  statisticsInsegna: InlineResponse2001
}

export interface ShippingAddress {
  surname: string
  name: string
  address: string
  street_number?: string
  building?: string
  city: string
  zipcode: string
  country?: string
  province?: string
  state?: string
  contact_note?: string
  contact_email?: string
  contact_number?: string
  address_notes?: string
  consumer_info?: {
    surname: string
    name: string
    reference_name: string
    contact_number: string
    contact_email: string
  }
  reference_name?: string
}

export interface StoreConsumerPayload {
  consumer: null | GetConsumerAttributes | PostConsumerResponseDataAttributes
  groupConsumer?: boolean
  noAlert?: boolean
}

export enum Mutations {
  SET_MODAL_CREDIT_CARD_INSTANCE = 'setModalCrediCardInstance',
  RESET_STATE = 'reset-state',
  UPDATE_SALE_DATA = 'update-sale-data',
  STORE_SALE = 'sale-store',
  CLEAR_SALE = 'sale-clear',
  STORE_SALE_IMPORTO_FINALE = 'sale-store-importo-finale',
  CLEAR_SALE_IMPORTO_FINALE = 'sale-clear-importo-finale',
  STORE_SALE_IMPORTO_PAGATO = 'sale-store-importo-pagato',
  CLEAR_SALE_IMPORTO_PAGATO = 'sale-clear-importo-',
  STORE_SALE_CAPI = 'sale-store-capi',
  CLEAR_SALE_CAPI = 'sale-clear-capi',
  STORE_SALE_PAGAMENTI = 'sale-store-pagamenti',
  CLEAR_SALE_PAGAMENTI = 'sale-clear-pagamenti',
  CLEAR_ONLY_PAYMENTS_TYPE = 'sale-clear-only-payments-type',
  STORE_PRODUCTS = 'sale-products-store',
  CLEAR_PRODUCTS = 'sale-products-clear',
  STORE_PROGRESSIVO_CAPO = 'sale-progressivo-capo-store',
  CLEAR_PROGRESSIVO_CAPO = 'sale-progressivo-capo-clear',
  STORE_PROGRESSIVO_PAGAMENTI = 'sale-progressivo-pagamenti-store',
  CLEAR_PROGRESSIVO_PAGAMENTI = 'sale-progressivo-pagamenti-clear',
  STORE_CONSUMER = 'sale-consumer-store',
  CLEAR_CONSUMER = 'sale-consumer-clear',
  STORE_ANONYMOUS_FLAG = 'sale-anonymous-flag-store',
  CLEAR_ANONYMOUS_FLAG = 'sale-anonymous-flag-clear',
  STORE_RDV = 'sale-rdv-store',
  CLEAR_RDV = 'sale-rdv-clear',
  STORE_CASHIER = 'sale-cashier-store',
  CLEAR_CASHIER = 'sale-cashier-clear',
  UPDATE_PRODUCT_DETAIL = 'sales-updateProductDetail',
  STORE_SKU_ALERT_OPEN = 'sales-store-sku-alert-open',
  STORE_CREATION_DATE = 'sales-store-creation-date',
  UPDATE_SALE_STATUS = 'sales-update-sale-status',
  STORE_ID_TRANSAZIONE = 'sales-store-id-transazione',
  CLEAR_ID_TRANSAZIONE = 'sales-clear-id-transazione',
  STORE_CART_VIEW = 'sales-store-cart-view',
  SET_GIFT_CARD_SAVE_IN_PROCESS = 'set-gift-card-save-in-process',
  STORE_DATA_VISIBILITY = 'sales-store-data-visibility',
  UPDATE_SUSPENDED_CREDIT = 'sales-store-update-suspended-credit',
  SET_SALES_HEADER_ICONS = 'set-sales-header-icons',
  UPDATE_PAYMENT_DATA = 'sales-update-payment-data',
  UPDATE_FULL_PAYMENT_DATA = 'sales-update-full-payment-data',
  SET_CART_TYPE = 'set-cart-type',
  SET_SELECTED_CART_TYPE = 'set-selected-cart-type',
  RESET_CART_TYPE = 'reset-cart-type',
  SET_TOTAL_PRICE_DEPOSIT = 'set-total-price-deposit',
  SET_TOTAL_PRICE_DEPOSIT_PAYMENT_METHOD = 'set-total-price-deposit-payment-method',
  SET_GIFT_RECEIPT = 'set-gift-receipt',
  SET_USED_CASH = 'set-used-cash',
  CLEAR_USED_CASH = 'sale-clear-used-cash',
  SET_CODICE_MOVIMENTO = 'set-codice-movimento',
  SET_DATI_STORNO = 'set-dati-storno',
  SET_DATI_STORNO_CURRENTSALE = 'set-sati-storno-in-currentsale',
  SET_TOTAL_CAPI = 'set-total-capi',
  SET_DISABLED_CONFIRM = 'set-disabled-confirm',
  CHECK_PRODUCTS_STOCK = 'check-product-stock',
  SET_CAN_CHANGE_CART_TYPE = 'set-can-change-cart-type',
  RESET_DATI_STORNO = 'reset-dati-storno',
  CHECK_ANTIRICICLAGGIO_RESULT = 'check-antireciclaggio-resutl',
  SET_INFO_GIACENZA_CAPI = 'set-info-giacenza-capi',
  REMOVE_INFO_GIACENZA_CAPI = 'remove-info-giacenza-capi',
  RESET_INFO_GIACENZA_CAPI = 'reset-info-giacenza-capi',
  ADD_COD_COMMESSA = 'add-cod-commessa',
}

export enum Actions {
  OPEN_CREDIT_CARD_MODAL = 'open-credit-card-modal',
  CLOSE_CREDIT_CARD_MODAL = 'close-credit-card-modal',
  RESET_STATE = 'reset-state',
  INIT_SALE = 'sale-init',
  SAVE_SALE = 'save-create',
  CREATE_SALE = 'sale-create',
  UPDATE_SALE = 'sale-update',
  HYDRATE_SALES = 'sale-hydrate',
  HYDRATE_SALES_SKUS = 'sale-skus-hydrate',
  SUSPEND_SALE = 'sale-suspend',
  TRY_PAYMENT_CREDIT_CARD = 'try-payment-credit-card',
  ADD_SKU = 'sale-add-sku',
  ADD_CAPO = 'sale-add-capo',
  REMOVE_CAPO = 'sale-remove-capo',
  UPDATE_CAPO = 'sale-update-capo',
  SET_CONSUMER = 'sale-set-consumer',
  RESET_CONSUMER = 'sale-reset-consumer',
  SET_CASHIER = 'sale-set-cashier',
  SET_MONEY_CHANGE = 'sale-set-money-change',
  RESET_CASHIER = 'sale-reset-cashier',
  SET_RDV = 'sale-set-rdv',
  RESET_RDV = 'sale-reset-rdv',
  SET_NOTA = 'sale-set-note',
  RESET_NOTA = 'sale-reset-note',
  SET_ANONYMOUS = 'sale-set-anonymous',
  SET_ANONYMOUS_CONSUMER = 'sale-set-anonymous-consumer',
  RESET_ANONYMOUS_CONSUMER = 'sale-reset-anonymous-consumer',
  SET_PAYMENTS = 'sale-set-payments',
  RESET_PAYMENTS = 'sale-reset-payments',
  RESET_ONLY_PAYMENTS_TYPE = 'sale-reset-only-payments-type',
  ADD_PAYMENT = 'sale-add-payment',
  EDIT_PAYMENT = 'sale-edit-payment',
  REMOVE_PAYMENT = 'sale-remove-payment',
  ADD_PAYMENT_DATA = 'sale-add-payment-data',
  RESET_DISCOUNTS = 'sale-reset-discounts',
  REMOVE_DISCOUNTS_VALIDATION = 'sale-remove-discounts-validation',
  CONFIRM_DISCOUNTS = 'sale-confirm-discounts',
  RESET_REBATES = 'sale-reset-rebates',
  REMOVE_REBATES_VALIDATION = 'sale-remove-rebates-validation',
  CONFIRM_REBATES = 'sale-confirm-rebates',
  RESET_TAILORING_ALTERATIONS = 'sale-reset-tailoring-alterations',
  CONFIRM_TAILORING_ALTERATIONS = 'sale-confirm-tailoring-alterations',
  DELETE_UNCONFIRMED_EXTRA_SERVICES = 'sale-delete-unconfirmed-extra-services',
  RESET_CAPO_ALTERATIONS = 'sale-reset-capo-alterations',
  SET_DOCUMENTO_DATI = 'sale-set-documento-dati',
  SET_DOCUMENTO_SPEDIZIONE = 'sale-set-documento-spedizione',
  RESET_DOCUMENTO_DATI = 'sale-reset-documento-dati',
  SET_DOCUMENTO_CODICE = 'sale-set-documento-codice',
  RESET_DOCUMENTO_CODICE = 'sale-reset-documento-codice',
  COMPUTE_TOTALS = 'sale-compute-totals',
  SET_DATA_VISIBILITY = 'set-data-visibility',
  PAY_GIFT_CARD = 'pay-gift-card',
  GET_NEW_TRANSACTION_ID = 'get-new-transaction-id',
  CLEAR_TRANSACTION_ID = 'clear-transaction-id',
  UPDATE_PAYMENT_DATA = 'sale-update-payment-data',
  UPDATE_FULL_PAYMENT_DATA = 'sale-update-full-payment-data',
  CLOSE_SALE = 'sale-close-sale',
  SET_CART_TYPE = 'set-cart-type',
  SET_SELECTED_CART_TYPE = 'set-selected-cart-type',
  RESET_CART_TYPE = 'reset-cart-type',
  SET_TOTAL_PRICE_DEPOSIT = 'set-total-price-deposit',
  SET_TOTAL_PRICE_DEPOSIT_PAYMENT_METHOD = 'set-total-price-deposit-payment-method',
  SET_GIFT_RECEIPT = 'set-gift-receipt',
  CLEAR_SALE_CAPI = 'clear-sale-capi',
  SET_USED_CASH = 'set-used-cash',
  CLEAR_USED_CASH = 'clear-used-cash',
  SET_CODICE_MOVIMENTO = 'set-codice-movimento',
  SET_DATI_STORNO = 'set-dati-storno',
  SET_DATI_STORNO_CURRENTSALE = 'set-dati-storno-currentsale',
  SET_TOTAL_CAPI = 'set-total-capi',
  SET_DISABLED_CONFIRM = 'set-disabled-confirm',
  CHECK_PRODUCTS_STOCK = 'check-product-stock',
  SET_CAN_CHANGE_CART_TYPE = 'set-can-change-cart-type',
  SET_INFO_GIACENZA_CAPI = 'set-info-giacenza-capi',
  REMOVE_INFO_GIACENZA_CAPI = 'remove-info-giacenza-capi',
  RESET_INFO_GIACENZA_CAPI = 'reset-info-giacenza-capi',
  STORE_ID_TRANSAZIONE = 'sales-store-id-transazione',
  STORE_CREATION_DATE = 'sales-store-creation-date',
  SET_SALE_DATI_PROMO = 'set-sale-dati-promo',
  ADD_COD_COMMESSA = 'add-cod-commessa',
  //
  // old actions
  UPDATE_TAILORING = 'sales-updateTailoring',
  SET_INITIAL_PRICE = 'sale-setInitialPrice',
  SET_FINAL_PRICE = 'sale-setFinalPrice',
  SET_TOTAL_PAYMENTS = 'sale-setTotalPayments',
  UPDATE_AVAILABLE_PAYMENTS_PROGRESSIVE = 'sales-setAvailablePaymentsProgressive',
  UPDATE_CURRENT_CAPO_ALTERATIONS = 'sales-updateCurrentCapoAlteration',
  CHECK_SALES_TRACE = 'sales-check-trace',
  CHECK_DUPLICATE_SKU = 'sales-checkDuplicateSku',
  UPDATE_PRODUCT_DETAIL = 'sales-updateProductDetail',
  UPDATE_PAYMENT_VALIDATION = 'sales-updatePaymentValidation',
  STORE_PAYMENT_AMOUNT = 'sales-storePaymentAmount',
  CHECK_COUPON = 'sales-checkCoupon',
  VALIDATE_COUPON = 'sales-validateCoupon',
  CONFIRM_COUPON = 'sales-confirmCoupon',
  DELETE_COUPON = 'sales-resetCoupon',
  UPDATE_COUPON_VALUE = 'sales-updateCouponValue',
  SET_TAILORING_VALIDATIONS_FALSE = 'sales-setTailoringValidationsFalse',
  ACTIVATE_GIFT_CARDS = 'sales-activateGiftCards',
  UPDATE_SUSPENDED_CREDIT = 'sales-update-suspended-credit',
  FETCH_SALES_HEADER_ICONS = 'fetch-sales-header-icons',
  CHECK_DATI_ANTIRICICLAGGIO = 'sales-check-dati-antiriciclaggio',
  STAMPA_RIEPILOGO_ANTIRICICLAGGIO = 'sales-stampa-riepilogo-antiriciclaggio',
  CHECK_TRANSACTION = 'check-transaction',
  RESET_DATI_STORNO = 'redset-dati-storno',
  RESET_DOCUMENTO_SPEDIZIONE = 'reset-documento-spedizione',
}

export enum Getters {
  GET_ALL_PRODUCT = 'get-all-product',
  PURCHASE_IS_ABOVE_THRESHOLD = 'is-anty-money-laundering',
  GET_CONSUMER_SAME_COUNTRY_CONDE = 'is-anti-money-laundering',
  GET_RDV = 'sales-getter-rdv',
  GET_CASHIER = 'sales-getter-cashier',
  GET_CONSUMER = 'sales-getter-consumer',
  GET_RDV_ID = 'sales-getter-rdv-id',
  GET_CASHIER_ID = 'sales-getter-cashier-id',
  GET_ANONYMOUS_ID = 'sales-getter-anonymous-id',
  GET_CONSUMER_ID = 'sales-getter-consumer-id',
  GET_RDV_LABEL = 'sales-getRdv-label',
  GET_CASHIER_LABEL = 'sales-getCashier-label',
  GET_CUSTOMER_LABEL = 'sales-getCustomer-label',
  GET_PAYMENTS = 'sales-getPayments',
  GET_ONLY_PAYMENTS = 'sales-getOnlyPayments',
  GET_PAYMENTS_TOTAL = 'sales-getPayments-total',
  GET_TOTAL_AMOUNT_CASHED_IN = 'sales-getPayments-totalAmountCashedIn',
  GET_DISCOUNT = 'sales-getDiscount',
  GET_DISCOUNT_DATA = 'sales-getDiscountValue',
  GET_LOYALTY_PROGRAM_DISCOUNT = 'sales-getLoyaltyProgramDiscount',
  GET_DOCUMENTO_SPEDIZIONE = 'sales-getDocumentoSpedizione',
  GET_REBATE = 'sales-getRebate',
  GET_TAILORING = 'sales-getTailoring',
  GET_PRODUCT_TAILORING = 'sales-getProductTailoring',
  GET_EXTRA_TAILORING = 'sales-getExtraTailoring',
  GET_FREE_EXTRA_TAILORING = 'sales-getFreeExtraTailoring',
  GET_EXTRA_TAILORING_PRICE = 'sales-getExtraTailoring-price',
  GET_TOTAL_PRICE = 'sales-getPrice-total',
  GET_CAPI_PRICE = 'sales-getPrice-capi',
  GET_CAPI = 'sales-getCapi',
  GET_CAPO = 'sales-get-capo',
  CHECK_FOR_ONE_CASH_PAYMENT = 'sales-checkOneCashPayment',
  CHECK_FOR_ONE_SUSPENDED_CREDIT_PAYMENT = 'sales-checkOneSuspendedCreditPayment',
  GET_CURRENT_SALE = 'sales-getCurrentSale',
  GET_ANONYMOUS = 'sales-getAnonymous',
  GET_CURRENT_CAPO_TAILORING = 'sales-getCurrentCapoTailoring',
  GET_PRODUCT = 'sales-get-product',
  GET_CAPO_ALTERATION = 'sales-get-capo-alteration',
  GET_CAPO_ALTERATION_TOTAL = 'sales-get-capo-alteration-total',
  GET_CREDITO_SOSPESO_ENABLED = 'get-credito-sospeso-enable',
  GET_SALE_IMPORTO_FINALE = 'get-sale-importo-finale',
  GET_CREDIT_CARD_PAYMENT_VALID = 'get-sale-credit-card-payment-valid',
  GET_CARDS_PAYMENT_IN_RETURNS_VALID = 'get-sale-credit-card-payment-in-returns-valid',
  GET_GIFT_CARD_PAYMENT_VALID = 'get-gift-card-payment-valid',
  GET_COUPON = 'get-sale-coupon',
  GET_SALE_STORE_STATUS = 'get-sale-store-status',
  GET_CART_VIEW = 'get-sale-cart-view',
  GET_DATA_VISIBILITY = 'get-data-visibility',
  GET_IS_GIFT_CARD_SALE = 'get-is-gift-card-sale',
  GET_SUSPENDED_CREDIT = 'get-suspended-credit',
  GET_SALES_HEADER_ICONS = 'get-sales-header-icons',
  GET_PAYMENT_DATA = 'get-payment-data',
  GET_PAYMENT_DATA_CASH = 'get-payment-data-cash',
  GET_CART_TYPE = 'get-cart-type',
  GET_MAX_IMPORT_CHEQUE = 'get-max-import-cheque',
  GET_SELECTED_CART_TYPE = 'get-selected-cart-type',
  GET_TOTAL_PRICE_DEPOSIT = 'get-total-price-deposit',
  GET_TOTAL_PRICE_DEPOSIT_PAYMENT_METHOD = 'get-total-price-deposit-spotify-payment-method',
  GET_IS_GROUP_CONSUMER = 'get-is-group-consumer',
  GET_IS_GIFT_CARD_SAVE_IN_PROCESS = 'get-gift-card-save-in-process',
  GET_USED_CASH = 'get-used-cash',
  GET_SUM_OF_DEBTPAYMENTS_VALIDATION_GIFTCARDSALE = 'get_sum_of_debtpaymets_validation_giftcardsale',
  IS_FREE_RETURN = 'is-free-return',
  IS_ALL_FREE_RETURN = 'is-all-free-return',
  GET_CODICE_MOVIMENTO = 'get-codice-movimento',
  GET_DATI_STORNO = 'get-dati-storno',
  GET_DISABLED_CONFIRM = 'get-disabled-confirm',
  GET_CAN_CHANGE_CART_TYPE = 'get-can-change-cart-type',
  GET_TAILORING_NO_EXTRA = 'get-tailoring-no-extra',
  IS_CASH_AMOUNT_ABOVE_MAXPAYABLE = 'is-cash-amount-above-maxpayable',
  GET_CHECK_ANTIRICICLAGGIO_RESULT = 'get-check-antireciclaggio-result',
  GET_INFO_GIACENZA_CAPI = 'get-info-giacenza-capi',
  GET_QUANTITY_INFO_GIACENZA_CAPI = 'get-quantity-info-giacenza-capi',
  IS_ONLY_TAILORING_ALTERATION_FREE_SALE = 'is-only-tailoring-alteration-free-sale',
  IS_ONLY_FREE_SALE = 'is-only-free-sale',
  IS_OSS_SALE = 'is-oss-sale',
  GET_DOCUMENTO_SPEDIZIONE_CONSUMER = 'sales-getDocumentoSpedizioneConsumer',
  GET_DOCUMENTO_SPEDIZIONE_STORE = 'sales-getDocumentoSpedizioneStore',
}
