/**
 * Initializes the application modules based on the configuration.
 *
 * This function iterates through the `MODULES` configuration,
 * which is an array of objects with `module` and `param` properties.
 * For each module, it checks if the corresponding
 * configuration parameter is truthy or set to `'NO_PARAM'`.
 * Configuration is obtained from store config trought api call (/api/v1/posweb/global_config)
 * If the condition is met, it dynamically imports the module
 * and calls its `init` function, passing the `app` instance.
 *
 * This function is responsible for bootstrapping the application's modules and ensuring
 * they are properly initialized based on the configuration.
 * es:
 *  param: 'ENDLESSAISLE_ENABLED',
    module: 'endless-aisle',
 */
import { FeLogger } from '@/addons/monitoring'
import { MODULES } from '@/configs'
// import { isConfigTruthy } from '@/addons/functions/configs'
import { app } from '@/main'
// import { GetStoreConfigDataAttributes } from '@/api'

export async function initModules() {
  FeLogger.info('Initializing modules...')
  for (const item of MODULES) {
    try {
      const { module } = item
      // if (isConfigTruthy(param) || param === 'NO_PARAM') {
      // eslint-disable-next-line no-await-in-loop
      const moduleImport = await import(
        /* @vite-ignore */ `@/modules/${module}/index.ts`
      )
      // eslint-disable-next-line no-await-in-loop
      await moduleImport.init(app)
      // }
    } catch {
      FeLogger.error(`Failed to initialize module: ${item.module}`)
      /* empty */
    }
  }
  FeLogger.info('Modules initialized.')
}
