import { LaybyCartTypeID, LaybyStatusType } from '@/addons/enums'
import { SalesState } from '../sales-types'
import { Getters, LaybyConsumerList, LaybyInfo } from './sales-lb-types'
import { LaybyElement } from '@/store/layby-store'
import store from '@/store'
import { ConfigGetters } from '@/store/configs-store'
import { SalesGetters } from '../sales-store'

export const laybyGetters = {
  [Getters.GET_LAYBY_INFO]: (state: SalesState): LaybyInfo => {
    return state.laybyInfo
  },
  [Getters.GET_LAYBY_STATUS]: (state: SalesState): string => {
    return state.laybyStatus
  },
  [Getters.GET_LAYBY_STATUS_EQUAL_TO]:
    (state: SalesState) =>
    (type: LaybyStatusType): boolean =>
      state.laybyStatus === type,
  [Getters.GET_IS_DEPOSIT_FLOW]: (state: SalesState): boolean =>
    state.selectedCartType?.id === LaybyCartTypeID.ACCONTO,
  [Getters.GET_LAYBY_MINIMUM_DEPOSIT]: (state: SalesState): number => {
    return state.laybyInfo.acconto_minimo
  },
  [Getters.GET_LAYBY_CONSUMER_LIST]: (
    state: SalesState,
  ): LaybyConsumerList[] => {
    return state.laybyConsumerList
  },
  [Getters.GET_LAYBY_RESUME_DATA]: (state: SalesState): LaybyElement => {
    return state.laybyResumeData
  },

  [Getters.GET_LAYBY_DEPOSIT_VALID]: (state: SalesState): boolean => {
    return state.laybyDepositValid
  },
  [Getters.GET_LAYBY_CALC_MINIMUM_DEPOSIT]: (): number => {
    const { LAYBY_MINIMUM, LAYBY_MINIMUM_UNIT } =
      store.getters[ConfigGetters.GET_STORE_CONFIGS]

    const parsedMinimum = parseFloat(LAYBY_MINIMUM ?? 0)
    const laybyMinimumAmount =
      LAYBY_MINIMUM_UNIT === '%'
        ? parseFloat(
            (
              (parsedMinimum / 100) *
              store.getters[SalesGetters.GET_TOTAL_PRICE]
            ).toFixed(2),
          )
        : parsedMinimum
    return laybyMinimumAmount
  },
}
