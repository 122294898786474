import { LaybyStatusType } from '@/addons/enums'

export const initLaybyState = () => ({
  laybyInfo: {
    totale: 0,
    acconto_minimo: 0,
    importo_oltre_acconto_minimo: 0,
    importo_mancante: 0,
    importo_rendibile: 0,
    importo_trattenuto: 0,
    prossimo_pagamento: 0,
    amountForPayment: 0,
    cancellato: 0,
    importo_pagato: 0,
    importo_pagamento: 0,
  },
  laybyStatus: LaybyStatusType.RECAP,
  laybyConsumerList: [],
  laybyResumeData: {},
  laybyDepositValid: false,
})
