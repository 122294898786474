import { DeliveryDataD } from '@/components/drawer/extra-services/delivery-section/delivery-section.d'

export interface State {
  statusValidateCheck: {
    success: boolean
    warning: boolean
    error: boolean
  }
  statusValidateCheckConsumer: {
    success: boolean
    warning: boolean
    error: boolean
  }
  statusValidateCheckStore: {
    success: boolean
    warning: boolean
    error: boolean
  }
  selectedAddress: DeliveryDataD
  selectedAddressConsumer: DeliveryDataD
  selectedAddressStore: DeliveryDataD
  addressTypeStore: string
}
export const initState = (): State => ({
  statusValidateCheck: {
    success: false,
    warning: false,
    error: false,
  },
  statusValidateCheckConsumer: {
    success: false,
    warning: false,
    error: false,
  },
  statusValidateCheckStore: {
    success: false,
    warning: false,
    error: false,
  },
  selectedAddress: {} as DeliveryDataD,
  selectedAddressConsumer: {} as DeliveryDataD,
  selectedAddressStore: {} as DeliveryDataD,
  addressTypeStore: '',
})
