import store from '@/store'
import { LaybyStatusType } from '@/addons/enums'

import { SalesState } from '../sales-types'
import { Mutations, LaybyInfo, CapiLaybyDelete } from './sales-lb-types'

import { initState } from '../sales-store'
import { ConfigGetters } from '@/store/configs-store'
import { consumerDataApi } from '@/addons/axios'
import { LaybyElementAttr } from '@/store/layby-store'

export const laybyMutations = {
  [Mutations.SET_LAYBY_INFO]: (state: SalesState, payload: LaybyInfo): void => {
    state.laybyInfo = payload
  },
  [Mutations.SET_LAYBY_STATUS]: (
    state: SalesState,
    payload: LaybyStatusType,
  ): void => {
    state.laybyStatus = payload
  },
  [Mutations.SET_STORE_SALE_LAYBY]: (
    state: SalesState,
    payload: LaybyInfo,
  ): void => {
    if (state.laybyStatus === LaybyStatusType.RECAP_DELETE) {
      payload['cancellato'] = 1
    }
    state.currentSale.layby = payload
  },
  [Mutations.RESET_LAYBY]: (state: SalesState): void => {
    const initLayby = initState()
    delete state.currentSale.layby
    state.laybyInfo = initLayby.laybyInfo
    state.laybyStatus = initLayby.laybyStatus
    state.selectedCartType = initLayby.cartType[0]
    state.laybyConsumerList = initLayby.laybyConsumerList
    state.laybyDepositValid = initLayby.laybyDepositValid
    state.consumer = undefined
  },
  [Mutations.RESET_LAYBY_DATA]: (state: SalesState): void => {
    delete state.currentSale.layby
    state.laybyInfo = initState().laybyInfo
  },
  [Mutations.RESET_LAYBY_RESUME_DATA]: (state: SalesState): void => {
    state.laybyResumeData = {}
  },

  [Mutations.SET_LAYBY_CONSUMER_LIST]: async (
    state: SalesState,
    pkConsumer: string,
  ): Promise<void> => {
    const filterDataA = store.getters[ConfigGetters.GET_CUSTOM_DATE]
      ? store.getters[ConfigGetters.GET_CUSTOM_DATE]
      : undefined

    const r = await consumerDataApi.apiV1ConsumersIconsPkConsumerGet(
      pkConsumer,
      filterDataA,
    )
    state.laybyConsumerList = r.data.data?.attributes?.layby
  },
  [Mutations.LAYBY_RESUME]: (
    state: SalesState,
    payload: LaybyElementAttr,
  ): void => {
    state.laybyResumeData = payload
    state.laybyStatus = LaybyStatusType.RECAP_RESUME
    state.selectedCartType = initState().cartType[1]
  },
  [Mutations.SET_CAPI_LAYBY_DELETE]: (state: SalesState): void => {
    state.currentSale.capi?.map((c: CapiLaybyDelete) => (c.layby_delete = true))
  },
  [Mutations.REMOVE_CAPI_LAYBY_DELETE]: (state: SalesState): void => {
    state.currentSale.capi?.map((c: CapiLaybyDelete) => delete c.layby_delete)
  },
  [Mutations.SET_LAYBY_DEPOSIT_VALID]: (
    state: SalesState,
    payload: boolean,
  ): void => {
    state.laybyDepositValid = payload
  },
  [Mutations.REMOVE_LAYBY_CURRENT_SALE]: (state: SalesState): void => {
    if ('layby' in state.currentSale) {
      delete state.currentSale?.layby
    }
  },
}
