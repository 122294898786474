import { ApiV1SalesDataAttributesCapi } from '@/api'

export interface LaybyInfo {
  totale: number
  acconto_minimo: number
  importo_oltre_acconto_minimo: number
  importo_mancante: number
  importo_rendibile: number
  importo_trattenuto: number
  prossimo_pagamento: number
  amountForPayment: number
  cancellato: number
  importo_pagato: number
  importo_pagamento: number
  [k: string]: number
}

export interface LaybyConsumerList {
  balance?: number
  cod_negozio?: string
  consumatrice?: string
  data_documento?: string
  expiring_date?: string
  id_transazione?: number
  importo_totale?: number
  layby_acconto_minimo?: number
  layby_importo_mancante?: number
  layby_importo_oltre_acconto_minimo?: number
  layby_importo_pagato?: number
  layby_importo_rendibile?: number
  layby_importo_trattenuto?: number
  money_return_limit_date?: string
  next_payment_date?: number
  numero_capi?: number
  numero_documento?: number
  numero_documento_orig?: string
  required_amount?: number
  total_paid_amount?: number
}

export interface CapiLaybyDelete extends ApiV1SalesDataAttributesCapi {
  layby_delete?: boolean
}

export enum Actions {
  SET_LAYBY_INFO = 'set-layby-info',
  SET_LAYBY_STATUS = 'set-layby-status',
  REMOVE_LAYBY_CURRENT_SALE = 'remove-layby-current-sale',
  SET_STORE_SALE_LAYBY = 'set-store-sale-layby',
  RESET_LAYBY = 'reset-layby',
  RESET_LAYBY_DATA = 'reset-layby-data',
  SET_LAYBY_CONSUMER_LIST = 'set-layby-list',
  LAYBY_RESUME = 'layby-resume',
  RESET_LAYBY_RESUME_DATA = 'reset-layby-resume-data',
  SET_CAPI_LAYBY_DELETE = 'set-capi-layby-delete',
  REMOVE_CAPI_LAYBY_DELETE = 'remove-capi-layby-delete',
  SET_LAYBY_DEPOSIT_VALID = 'set-layby-deposit-valid',
}

export enum Mutations {
  SET_LAYBY_INFO = 'set-layby-info',
  SET_LAYBY_STATUS = 'set-layby-status',
  REMOVE_LAYBY_CURRENT_SALE = 'remove-layby-current-sale',
  SET_STORE_SALE_LAYBY = 'set-store-sale-layby',
  RESET_LAYBY = 'reset-layby',
  RESET_LAYBY_DATA = 'reset-layby-data',
  SET_LAYBY_CONSUMER_LIST = 'set-layby-list',
  LAYBY_RESUME = 'layby-resume',
  RESET_LAYBY_RESUME_DATA = 'reset-layby-resume-data',
  SET_CAPI_LAYBY_DELETE = 'set-capi-layby-delete',
  REMOVE_CAPI_LAYBY_DELETE = 'remove-capi-layby-delete',
  SET_LAYBY_DEPOSIT_VALID = 'set-layby-deposit-valid',
}

export enum Getters {
  GET_LAYBY_INFO = 'get-layby-info',
  GET_LAYBY_STATUS = 'get-layby-status',
  GET_LAYBY_STATUS_EQUAL_TO = 'get-layby-status-equal-to',
  GET_IS_DEPOSIT_FLOW = 'GET_IS_DEPOSIT_FLOW',
  GET_LAYBY_MINIMUM_DEPOSIT = 'get-layby-minimum-deposit',
  GET_LAYBY_CONSUMER_LIST = 'get-layby-list',
  GET_LAYBY_RESUME_DATA = 'get-layby-resume-data',
  GET_LAYBY_DEPOSIT_VALID = 'get-layby-deposit-valid',
  GET_LAYBY_CALC_MINIMUM_DEPOSIT = 'get-layby-calc-minimum-deposit',
}
