import { ApiV1SalesDataAttributes, ApiV1SalesSaleIdDataAttributes } from '@/api'
import { RootState } from '@/store'
import { selectedConsumerStatus } from '@/store/consumer/index.d'
import { SyncResult } from '@capacitor/live-updates'
import { Payload } from 'vuex'
import { LocalStorageKeys, SessionKeys, TipiMovimenti } from './enums'

export function handlePersistance(mutation: Payload, state: RootState) {
  if (
    mutation.type.includes('sale-') &&
    Object.keys(state.sales.currentSale).length
  ) {
    const tempSale: ApiV1SalesDataAttributes &
      ApiV1SalesSaleIdDataAttributes & {
        selectedConsumerStatus: selectedConsumerStatus | undefined
      } = { ...state.sales.currentSale }
    tempSale.pk_consumer =
      state.sales?.currentSale?.pk_consumer ||
      state.consumers?.currentConsumer?.pk_consumer
    tempSale.pagamenti = tempSale.pagamenti?.filter((p) => {
      if (state.payments.payments_validator[p.progressivo as number] === null) {
        return false
      }

      if (p.codice_movimento !== TipiMovimenti.CONTABILITA_PAGAMENTO) {
        return true
      }

      return state.payments.payments_validator[p.progressivo as number]
    })

    sessionStorage.setItem(SessionKeys.CURRENT_SALE, JSON.stringify(tempSale))
  }
}

export function clearLocalStorage() {
  for (const key in localStorage) {
    if (key !== LocalStorageKeys.LIVE_UPDATES_INSTALLED_VERSION) {
      localStorage.removeItem(key)
    }
  }
}

export function saveLiveUpdateResults(details: SyncResult) {
  localStorage.setItem(
    LocalStorageKeys.LIVE_UPDATES_INSTALLED_VERSION,
    JSON.stringify(details),
  )
}

export function getLiveUpdateResults(): SyncResult | undefined {
  const value = localStorage.getItem(
    LocalStorageKeys.LIVE_UPDATES_INSTALLED_VERSION,
  )

  return value ? JSON.parse(value) : undefined
}

export function deleteLiveUpdateResults() {
  localStorage.removeItem(LocalStorageKeys.LIVE_UPDATES_INSTALLED_VERSION)
}
