import { ActionContext } from 'vuex'
import { RootState } from '@/store'
import { SalesState, Actions as ActionsST } from '../sales-types'
import {
  Actions as ActionsLB,
  LaybyInfo,
  Mutations as MutationsLB,
} from './sales-lb-types'
import { LaybyStatusType, SessionKeys } from '@/addons/enums'
import { salesApi } from '@/addons/axios'

export const laybyActions = {
  [ActionsLB.SET_LAYBY_INFO]: (
    context: ActionContext<SalesState, RootState>,
    payload: { [key: string]: string | number },
  ) => {
    context.commit(
      MutationsLB.SET_LAYBY_INFO,
      Object.assign({}, context.state.laybyInfo, payload),
    )
  },
  [ActionsLB.SET_LAYBY_STATUS]: (
    context: ActionContext<SalesState, RootState>,
    payload: LaybyStatusType,
  ) => {
    context.commit(MutationsLB.SET_LAYBY_STATUS, payload)
  },
  [ActionsLB.REMOVE_LAYBY_CURRENT_SALE]: (
    context: ActionContext<SalesState, RootState>,
  ): void => {
    context.commit(MutationsLB.REMOVE_LAYBY_CURRENT_SALE)
  },
  [ActionsLB.SET_STORE_SALE_LAYBY]: (
    context: ActionContext<SalesState, RootState>,
  ) => {
    context.commit(MutationsLB.SET_STORE_SALE_LAYBY, context.state?.laybyInfo)
  },
  [ActionsLB.RESET_LAYBY]: (context: ActionContext<SalesState, RootState>) => {
    context.commit(MutationsLB.RESET_LAYBY)
  },
  [ActionsLB.RESET_LAYBY_DATA]: (
    context: ActionContext<SalesState, RootState>,
  ) => {
    context.commit(MutationsLB.RESET_LAYBY_DATA)
  },
  [ActionsLB.SET_LAYBY_CONSUMER_LIST]: (
    context: ActionContext<SalesState, RootState>,
    payload?: string,
  ) => {
    context.commit(
      MutationsLB.SET_LAYBY_CONSUMER_LIST,
      payload ?? context.state.consumer?.pk_consumer,
    )
  },
  [ActionsLB.LAYBY_RESUME]: async (
    context: ActionContext<SalesState, RootState>,
    payload: {
      cod_negozio: string
      id_transazione: number
      laybyInfo: LaybyInfo
    },
  ) => {
    const response = await salesApi.apiV1SalesSaleIdGet(
      `${payload.cod_negozio},${payload.id_transazione}`,
    )

    await context.dispatch(
      ActionsST.HYDRATE_SALES_SKUS,
      response.data.data?.attributes?.capi,
    )

    context.commit(MutationsLB.LAYBY_RESUME, response.data.data?.attributes)
    sessionStorage.setItem(
      SessionKeys.LAYBY_RESUME,
      JSON.stringify({
        laybyStatus: context.state.laybyStatus,
        layby: response.data.data?.attributes,
        laybyInfo: payload.laybyInfo,
      }),
    )
  },
  [ActionsLB.RESET_LAYBY_RESUME_DATA]: (
    context: ActionContext<SalesState, RootState>,
  ) => {
    context.commit(MutationsLB.RESET_LAYBY_RESUME_DATA)
  },
  [ActionsLB.SET_CAPI_LAYBY_DELETE]: (
    context: ActionContext<SalesState, RootState>,
  ) => {
    context.commit(MutationsLB.SET_CAPI_LAYBY_DELETE)
  },
  [ActionsLB.REMOVE_CAPI_LAYBY_DELETE]: (
    context: ActionContext<SalesState, RootState>,
  ) => {
    context.commit(MutationsLB.REMOVE_CAPI_LAYBY_DELETE)
  },
  [ActionsLB.SET_LAYBY_DEPOSIT_VALID]: (
    context: ActionContext<SalesState, RootState>,
    payload: boolean,
  ): void => {
    context.commit(MutationsLB.SET_LAYBY_DEPOSIT_VALID, payload)
  },
}
