import { ActionTree } from 'vuex'
import { RootState } from '@/store'
import { State } from './_state'
import { MutationsNames } from '@/modules/address-validation/store/_mutations'
import { DeliveryDataD } from '@/components/drawer/extra-services/delivery-section/delivery-section.d'
export enum ActionsNames {
  SET_STATUS_CHECK = 'set-status-check',
  SET_SELECTED_ADDRESS = 'set-selected-addres',
  RESET_ADDRESS_VALIDATION = 'reset-address-validation',
  SET_STATUS_CHECK_CONSUMER = 'set-status-check_consumer',
  SET_STATUS_CHECK_STORE = 'set-status-check_store',
  SET_SELECTED_ADDRESS_CONSUMER = 'set-selected-addres-consumer',
  SET_SELECTED_ADDRESS_STORE = 'set-selected-addres-store',
  SET_ADDRESS_TYPE = 'set-address-type',
  RESET_ADDRESS_VALIDATION_SHIPPING = 'reset-address-validation-shipping',
}

export interface Actions {
  [ActionsNames.SET_STATUS_CHECK](payload: State['statusValidateCheck']): void
  [ActionsNames.SET_SELECTED_ADDRESS](payload: DeliveryDataD): void
  [ActionsNames.RESET_ADDRESS_VALIDATION](): void
  [ActionsNames.SET_STATUS_CHECK_CONSUMER](
    payload: State['statusValidateCheckConsumer'],
  ): void
  [ActionsNames.SET_STATUS_CHECK_STORE](
    payload: State['statusValidateCheckStore'],
  ): void
  [ActionsNames.SET_SELECTED_ADDRESS_CONSUMER](payload: DeliveryDataD): void
  [ActionsNames.SET_SELECTED_ADDRESS_STORE](payload: DeliveryDataD): void
  [ActionsNames.SET_ADDRESS_TYPE](payload: string): void
  [ActionsNames.RESET_ADDRESS_VALIDATION_SHIPPING](): void
}

export const actions: ActionTree<State, RootState> = {
  [ActionsNames.SET_STATUS_CHECK](
    context,
    payload: State['statusValidateCheck'],
  ) {
    context.commit(MutationsNames.SET_STATUS_CHECK, payload)
  },
  [ActionsNames.SET_SELECTED_ADDRESS](context, payload: DeliveryDataD) {
    context.commit(MutationsNames.SET_SELECTED_ADDRESS, payload)
  },
  [ActionsNames.RESET_ADDRESS_VALIDATION](context) {
    context.commit(MutationsNames.RESET_ADDRESS_VALIDATION)
  },
  [ActionsNames.SET_STATUS_CHECK_CONSUMER](
    context,
    payload: State['statusValidateCheckConsumer'],
  ) {
    context.commit(MutationsNames.SET_STATUS_CHECK_CONSUMER, payload)
  },
  [ActionsNames.SET_STATUS_CHECK_STORE](
    context,
    payload: State['statusValidateCheckStore'],
  ) {
    context.commit(MutationsNames.SET_STATUS_CHECK_STORE, payload)
  },
  [ActionsNames.SET_SELECTED_ADDRESS_CONSUMER](
    context,
    payload: DeliveryDataD,
  ) {
    context.commit(MutationsNames.SET_SELECTED_ADDRESS_CONSUMER, payload)
  },
  [ActionsNames.SET_SELECTED_ADDRESS_STORE](context, payload: DeliveryDataD) {
    context.commit(MutationsNames.SET_SELECTED_ADDRESS_STORE, payload)
  },
  [ActionsNames.SET_ADDRESS_TYPE](context, payload: string) {
    context.commit(MutationsNames.SET_ADDRESS_TYPE, payload)
  },
  [ActionsNames.RESET_ADDRESS_VALIDATION_SHIPPING](context) {
    context.commit(MutationsNames.RESET_ADDRESS_VALIDATION_SHIPPING)
  },
}
